import React, { Component } from 'react'
import { Images } from '../Themes'
import { Header } from '../components/navbar'
import PersonSection from '../components/PersonSection'
import Section from '../components/Section'
import FAQ from '../components/Faq'
import SectionStrip from '../components/SectionStrip'
import BusinessTypeStrip from '../components/BusinessTypeStrip'
import CTABox from '../components/CTABox'
import TryButton from '../components/TryButton'
import scrollToComponent from 'react-scroll-to-component'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      buttonText: props.t('tryNow'),
      scrollEffect: 25 // Initial rotation angle
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const maxScroll = 500 // Maximum scroll distance to reach 0deg
    const scrollY = window.scrollY
    const rotationAngle = Math.max(0, 25 - (scrollY / maxScroll) * 25)
    this.setState({ scrollEffect: rotationAngle })
  }

  onDemoClick = props => {
    const { i18n } = props
    let locale = 'en'

    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }

    window.open(`https://home.freshsuq.com/${locale}/setup`)
  }

  render() {
    const { t, i18n } = this.props
    const { scrollEffect } = this.state
    const direction = i18n.language === 'ar' ? 'end' : 'start'
    const ulDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `/${i18n.language.toString()}`
    }
    const requestDemoUrl = `${locale}/get-free-demo`
    const textDirection = i18n.language === 'ar' ? 'text-right' : 'text-left'

    return (
      <React.Fragment>
        <Helmet>
          <title>{t('title')}</title>
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <Header
          ShowToggle={true}
          onHomeClick={() =>
            scrollToComponent(this.home, { offset: -230, align: 'top' })
          }
          onFeaturesClick={() =>
            scrollToComponent(this.features, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onPricingClick={() =>
            scrollToComponent(this.pricing, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          onHardwareClick={() =>
            scrollToComponent(this.hardware, {
              offset: isMobile ? -230 : 0,
              align: 'top'
            })
          }
          demoButtonText={this.state.buttonText}
        />
        <div className="hero">
          <div className="heroSection"></div>
        </div>
        <section className="pb-0 pt-lg-4 u-h-90vh pb-5 mt-5 topSection">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-5 text-left mx-auto u-pt-20 mb-lg-0 mb-2">
                <h2 className="u-fs-50 u-fw-600 text-center px-1 text-dark-black">
                  {t('Everything For Your Business')}
                </h2>
                <h1 className="u-fs-27 h2 text-dark-black text-center u-lh-1_8 py-4 px-2">
                  {t(
                    'Simplifies running your business, by allowing you to streamline your orders'
                  )}
                </h1>

                <div className="row justify-content-center">
                  <a
                    className="btn my-4 mx-2 text-white btn-black"
                    onClick={() => this.onDemoClick(this.props)}
                  >
                    <p className="u-fs-23 u-fw-400 text-center text-white">
                      {t('Try Trial')}
                    </p>
                  </a>
                  <a
                    className="btn my-4 px-4 mx-2 text-black"
                    href={`${locale}/pricing`}
                  >
                    <p className="u-fs-25 text-center">{t('seePricing')}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mx-4 pt-5"
            style={{
              willChange: 'transform',
              opacity: 1,
              transform: `perspective(1200px) rotateX(${scrollEffect}deg)`,
              transition: 'transform 0.1s ease-out',
              display: 'flex',
              backgroundColor: 'transparent',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              className="mx-lg-5 mx-sm-2 px-lg-5 heroImage"
              decoding="async"
              sizes="max(min(100vw - 96px, 1344px), 1px)"
              src={Images.hero_image}
              style={{
                borderRadius: 'inherit',
                objectPosition: 'center center',
                objectFit: 'cover'
              }}
            />
          </div>
        </section>
        <BusinessTypeStrip t={t} />

        <section className="p-3 p-lg-5 u-mt-60 d-block justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="h1 u-fw-700 text-center">
                {t('POSSectionTitle')}
              </h2>
            </div>

            <div className="container mt-5">
              <div className="row g-4">
                <div className="col-lg-4 col-md-6 mt-3">
                  <div className="h-100 px-md-3 py-5 bg-light-grey u-rounded-10">
                    <p className="h5 text-center pl-3">
                      {t('home.InventoryManagmentTitle')}
                    </p>
                    <ul className="ml-lg-2" dir={ulDirection}>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.InventoryManagment1')}
                        </p>
                      </li>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.InventoryManagment2')}
                        </p>
                      </li>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.InventoryManagment3')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <div className="h-100 px-md-3 py-5 bg-light-grey u-rounded-10">
                    <p className="h5 text-center pl-3">
                      {t('home.SalesAnalyticsTitle')}
                    </p>
                    <ul className="ml-lg-2" dir={ulDirection}>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.SalesAnalytics1')}
                        </p>
                      </li>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.SalesAnalytics2')}
                        </p>
                      </li>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.SalesAnalytics3')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <div className="h-100 px-md-3 py-5 bg-light-grey u-rounded-10">
                    <p className="h5 text-center pl-3">
                      {t('home.LoyaltyProgramTitle')}
                    </p>
                    <ul className="ml-lg-2" dir={ulDirection}>
                      <li className="mt-4 text-center">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.LoyaltyProgram1')}
                        </p>
                      </li>
                      <li className="mt-4">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.LoyaltyProgram2')}
                        </p>
                      </li>
                      <li className="mt-4">
                        <p className={`text-black u-fs-16 ${textDirection}`}>
                          {t('home.LoyaltyProgram3')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <CTABox t={t} i18n={i18n} />

        <section className="p-4 u-my-100 d-block justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="h1 text-center u-fw-700">
                {t('InventorySectionTitle')}
              </h2>
              <p className="text-center u-fs-30 pt-4 text-black">
                {t('InventorySectionSubTitle')}
              </p>
            </div>

            <div className="col-lg-11 m-lg-5 m-3">
              <div className="row justify-content-center">
                <div className="col-lg-6 u-pt-20 mt-3 p-3">
                  <img
                    className="u-rounded-25 normal-image"
                    src={Images.inventory_ordering}
                    alt=""
                  />
                </div>

                <div className="col-lg-6 u-pt-20 mt-3 p-3">
                  <div className="px-1 py-4 px-md-5 align-items-center card-shadow bg-white u-rounded-25 u-h-100p u-flex-center ">
                    <img
                      className="u-rounded-25 small-image"
                      src={Images.product_connect}
                      alt=""
                    />
                    <p className="h3 u-pb-20 u-pt-20 text-black text-center mt-4">
                      {t('AddNewProducts')}
                    </p>
                    <p className="h5 text-center text-black">
                      {t('AddNewProductsMsg')}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mt-4">
                <div className="col-lg-4 col-md-6 mt-3">
                  <div
                    className="h-100 px-md-3 py-5 card-shadow bg-white u-rounded-25"
                    style={{ zIndex: -1 }}
                  >
                    <div className="px-4 px-md-5 py-5 justify-content-center">
                      <p className="h3 text-center text-black">
                        {t('inventory.UnlimitedStock')}
                      </p>
                      <p className="u-fs-24 mt-4 text-center text-dark-black">
                        {t('inventory.UnlimitedStockSubtitle')}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <div
                    className="h-100 px-md-3 py-5 card-shadow bg-white u-rounded-25"
                    style={{ zIndex: -1 }}
                  >
                    <div className="px-4 py-5 px-md-5">
                      <p className="h3 text-center text-black">
                        {t('LowInventoryNotification')}
                      </p>
                      <p className="u-fs-24 mt-4 text-center text-dark-black">
                        {t('LowInventoryMsg')}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mt-3">
                  <div
                    className="h-100 px-md-3 py-5 card-shadow bg-white u-rounded-25"
                    style={{ zIndex: -1 }}
                  >
                    <div className="px-4 py-5 px-md-5">
                      <p className="h3 text-center text-black">
                        {t('inventory.PurchaseOrders')}
                      </p>
                      <p className="u-fs-24 mt-4 text-center text-dark-black">
                        {t('inventory.PurchaseOrdersSubtitle')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-4 d-block justify-content-center">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <h2 className="h1 text-center u-fw-700">{t('Reports')}</h2>
              <p className="text-center u-fs-30 pt-4">
                {t('ReportsSubsection')}
              </p>
            </div>
          </div>
        </section>

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.sales_report}
          title={t('SalesReports')}
          addShadow={true}
          subtitle={t('SalesReportsMsg')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={false}
          i18n={i18n}
          image={Images.tax_report}
          title={t('TaxReports')}
          addShadow={true}
          subtitle={t('TaxReportsMsg')}
          renderButton={<TryButton props={this.props} />}
        />

        <Section
          modelLeft={true}
          i18n={i18n}
          image={Images.payments_report}
          title={t('PaymentsReports')}
          addShadow={true}
          subtitle={t('PaymentsReportsMsg')}
          renderButton={<TryButton props={this.props} />}
        />

        <SectionStrip t={t} />
        <CTABox t={t} i18n={i18n} />
        <FAQ t={t} i18n={i18n} />

        <PersonSection
          direction={direction}
          renderButton={<TryButton props={this.props} />}
          title={t('ProfessionalServices')}
          subtitle={t('ProfessionalServicesSubtitle')}
        />
      </React.Fragment>
    )
  }
}

export default withTranslation()(HomePage)
