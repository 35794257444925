import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MetaTags from 'react-meta-tags'
import '../assets/css/bootstrap.min.css'
import '../assets/css/site.css'
import './styles/App.css'
import '../assets/css/custom.css'
import { withTranslation } from 'react-i18next'

// routes
import { Main } from '../routes'

class RootContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    // const { i18n } = this.props
    // let currentLang = 'en'
    // if (i18n.language === 'ar') {
    //   currentLang = 'ar'
    // }
    // ;(function(d, t) {
    //   const script = document.createElement('script')
    //   script.setAttribute('type', 'text/javascript')
    //   let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "f9223b8d8c5ab1fac375354acb52a91d702575bad1f8d8336bf3191a32ba71a3", values:{},ready:function(){ $zoho.salesiq.language("${currentLang}")}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`
    //   script.appendChild(document.createTextNode(code))
    //   document.body.appendChild(script)
    //   return () => {
    //     document.body.removeChild(script)
    //   }
    // })(document, 'script')
  }

  getOtherLangUrl = otherLang => {
    let origString = window.location.pathname
    let indexPosition = 1
    let otherLangUrl = window.location.href
    origString = origString.split('/')
    origString.splice(indexPosition, 1, otherLang)
    origString.join('/')
    otherLangUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      origString.join('/')
    return otherLangUrl
  }

  addPageLink = () => {
    const { i18n } = this.props
    const lang = i18n.language
    const otherLang = lang === 'ar' ? 'en' : 'ar'

    return (
      <MetaTags>
        <link rel="alternate" hrefLang={lang} href={window.location.href} />
        <link
          rel="alternate"
          hrefLang={otherLang}
          href={this.getOtherLangUrl(otherLang)}
        />
      </MetaTags>
    )
  }

  renderRoutes() {
    return (
      <div id="container" className="effect footer-fixed mainnav-lg">
        <div id="floating-top-right" className="panel-alert">
          <ToastContainer autoClose={2000} />
        </div>
        <Main />
      </div>
    )
  }

  render() {
    return this.renderRoutes()
  }
}

export default withTranslation()(RootContainer)
