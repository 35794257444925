import React from 'react'

const InventoryAppSection = props => {
  const { i18n, addShadow, t } = props
  const shadowClass = addShadow ? 'box-shadow-v1' : ''
  const textDirection =
    i18n.language === 'ar' ? 'text-lg-right' : 'text-lg-left'
  const titleTextSize = i18n.language === 'ar' ? '40' : '40'

  return (
    <section className="pt-5 pb-0">
      <div className="container">
        <div className="row align-items-center">
          <div
            className={`col-lg-6 p-5 text-center ${
              props.modelLeft ? 'order-lg-1' : 'order-lg-2'
            }`}
          >
            <img
              className={`invetory-modal-image ${shadowClass}`}
              src={props.image}
              alt="FreshSUQ Store"
            />
          </div>
          <div
            className={`col-lg-6 p-3 ${
              props.modelLeft ? 'order-lg-2' : 'order-lg-1'
            }`}
          >
            <h2
              className={`text-black ${titleTextSize} ${textDirection} text-center`}
            >
              {props.title}
            </h2>
            <ul
              className={`text-dark mt-5 u-fs-26 ${textDirection} text-center no-bullets`}
            >
              <li className="pt-3">{t('inventorySubheader')}</li>
              <div className="row mt-5">
                <div className="col-md-6 d-flex flex-column">
                  <li className="inventory-feature-item d-flex align-items-start mb-4">
                    <i className="fa fa-check-circle text-success mr-2 mt-1"></i>
                    <span className="text-nowrap">
                      {t('inventory.UnlimitedStock')}
                    </span>
                  </li>
                  <li className="inventory-feature-item d-flex align-items-start mb-4">
                    <i className="fa fa-check-circle text-success mr-2 mt-1"></i>
                    <span className="text-nowrap">
                      {t('inventory.InventoryCount')}
                    </span>
                  </li>
                </div>
                <div className="col-md-6 d-flex flex-column">
                  <li className="inventory-feature-item d-flex align-items-start mb-4">
                    <i className="fa fa-check-circle text-success mr-2 mt-1"></i>
                    <span className="text-nowrap">
                      {t('inventory.PurchaseOrders')}
                    </span>
                  </li>
                  <li className="inventory-feature-item d-flex align-items-start mb-4">
                    <i className="fa fa-check-circle text-success mr-2 mt-1"></i>
                    <span>{t('LowInventoryNotification')}</span>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InventoryAppSection
