export default class PricingService {
  constructor(interval, country) {
    this.interval = interval
    this.country = country
    this.plansCostDetails = this.setPlan()
  }

  static countries = [
    {
      value: 'US',
      label: 'United States',
      currency: 'USD',
      pricing: {
        monthly: {
          basic: 0,
          advanced: 29,
          pro: 59,
          addons: {
            'Extra Location': 10,
            'Extra Register': 5,
            'Extra Employee': 5,
            'Employee Management': 5,
            'Inventory Management (Advanced)': 5,
            'Reports & Analytics (Advanced)': 10,
            'Table Management': 5,
            'Loyalty Program': 5,
            'Customer Display Screen': 5,
            'Kitchen Display Screen': 10
          }
        },
        annually: {
          basic: 0,
          advanced: 290,
          pro: 590,
          addons: {
            'Extra Location': 110,
            'Extra Register': 55,
            'Extra Employee': 55,
            'Employee Management': 55,
            'Inventory Management (Advanced)': 50,
            'Reports & Analytics (Advanced)': 110,
            'Table Management': 55,
            'Loyalty Program': 55,
            'Customer Display Screen': 55,
            'Kitchen Display Screen': 110
          }
        }
      }
    },
    {
      value: 'SA',
      label: 'Saudi Arabia',
      currency: 'SAR',
      pricing: {
        monthly: {
          basic: 0,
          advanced: 109,
          pro: 221,
          addons: {
            'Extra Location': 38,
            'Extra Register': 19,
            'Extra Employee': 19,
            'Employee Management': 19,
            'Inventory Management (Advanced)': 19,
            'Reports & Analytics (Advanced)': 38,
            'Table Management': 19,
            'Loyalty Program': 19,
            'Customer Display Screen': 19,
            'Kitchen Display Screen': 38
          }
        },
        annually: {
          basic: 0,
          advanced: '1,090',
          pro: '2,210',
          addons: {
            'Extra Location': 418,
            'Extra Register': 209,
            'Extra Employee': 209,
            'Employee Management': 209,
            'Inventory Management (Advanced)': 209,
            'Reports & Analytics (Advanced)': 418,
            'Table Management': 209,
            'Loyalty Program': 209,
            'Customer Display Screen': 209,
            'Kitchen Display Screen': 418
          }
        }
      }
    },
    {
      value: 'CO',
      label: 'Colombia',
      currency: 'COP',
      pricing: {
        monthly: {
          basic: 0,
          advanced: '123.000',
          pro: '250.000',
          addons: {
            'Extra Location': '42.304',
            'Extra Register': '21.152',
            'Extra Employee': '21.152',
            'Employee Management': '21.152',
            'Inventory Management (Advanced)': '21.152',
            'Reports & Analytics (Advanced)': '42.304',
            'Table Management': '21.152',
            'Loyalty Program': '21.152',
            'Customer Display Screen': '21.152',
            'Kitchen Display Screen': '42.304'
          }
        },
        annually: {
          basic: 0,
          advanced: '1.230.000',
          pro: '2.500.000',
          addons: {
            'Extra Location': '465.344',
            'Extra Register': '232.672',
            'Extra Employee': '232.672',
            'Employee Management': '232.672',
            'Inventory Management (Advanced)': '232.672',
            'Reports & Analytics (Advanced)': '465.344',
            'Table Management': '232.672',
            'Loyalty Program': '232.672',
            'Customer Display Screen': '232.672',
            'Kitchen Display Screen': '465.344'
          }
        }
      }
    }
  ]

  static getCountries = () => {
    return PricingService.countries.map(({ value, label }) => ({
      value,
      label
    }))
  }

  static getCountryByValue = value => {
    return PricingService.countries.find(country => country.value === value)
  }

  setPlan = () => {
    const { pricing } = this.country

    return {
      basic: pricing[this.interval].basic,
      advanced: pricing[this.interval].advanced,
      pro: pricing[this.interval].pro,
      addons: this.country.pricing[this.interval].addons
    }
  }
}
