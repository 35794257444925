import React from 'react'

const TryButton = ({ props }) => {
  const { t, i18n } = props
  let locale = 'en'

  if (i18n.languages.includes(i18n.language)) {
    locale = `${i18n.language.toString()}`
  }

  return (
    <a
      href={`https://home.freshsuq.com/${locale}/setup`}
      target="_blank"
      rel="noopener noreferrer"
      className="btn btn-black my-4 px-4 mx-2 d-block d-md-inline-block text-white"
    >
      <p className="u-fs-23 u-fw-400 text-center text-white">
        {t('Try Trial')}
      </p>
    </a>
  )
}

export default TryButton
