import React from 'react'

const Section = props => {
  const { i18n, addShadow } = props
  const shadowClass = addShadow ? 'box-shadow-v1' : ''
  const textDirection =
    i18n.language === 'ar' ? 'text-lg-right' : 'text-lg-left'
  const titleTextSize = i18n.language === 'ar' ? '40' : '30'
  const subtTitleTextSize = i18n.language === 'ar' ? '25' : '25'

  return (
    <section className="pt-5 mt-5 pb-5">
      <div className="container">
        <div className="gradient-background">
          <div className="row align-items-center py-5">
            <div
              className={`col-lg-6 text-center ${
                props.modelLeft ? 'order-lg-1' : 'order-lg-2'
              }`}
            >
              <img
                className={`modal-image  ${shadowClass}`}
                src={props.image}
                alt="FreshSUQ Store"
              />
            </div>
            <div
              className={`col-lg-6 p-5 ${
                props.modelLeft ? 'order-lg-2' : 'order-lg-1'
              }`}
            >
              <h2
                className={`text-black ${titleTextSize} pl-2 ${textDirection} text-center`}
              >
                {props.title}
              </h2>
              <p
                className={`u-fs-${subtTitleTextSize} ${textDirection} text-center pl-2 text-light-black u-lh-1_57 u-pt-30 my-4`}
              >
                {props.subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section
