import React from 'react'
import ReactDOM from 'react-dom'
// import $ from 'jquery';
import '../node_modules/font-awesome/css/font-awesome.min.css'
import './index.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import App from './containers/App'
import {register} from './registerServiceWorker'
import { I18nextProvider } from 'react-i18next'
import translation from './translations/translation'

ReactDOM.render(
  <I18nextProvider i18n={translation}>
    <App i18n={translation} />
  </I18nextProvider>,
  document.getElementById('root')
)
register();
