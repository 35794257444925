import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Images } from '../../Themes'
import NavbarToggle from './NavbarToggle'
import { withTranslation } from 'react-i18next'
import { MobileView } from 'react-device-detect'
import MobileTryButton from '../MobileTryButton'

class Header extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collapsed: true,
      showMobileFooter: false
    }

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    const { i18n } = this.props
    if (i18n.language === 'ar') {
      document.body.classList.toggle('font-face-ar', true)
      document.body.classList.toggle('font-face-en', false)
      document.documentElement.setAttribute('lang', 'ar')
    } else {
      document.body.classList.toggle('font-face-ar', false)
      document.body.classList.toggle('font-face-en', true)
      document.documentElement.setAttribute('lang', 'en')
    }
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  elementInViewport = () => {
    var myElement = document.getElementById('footer')
    var bounding = myElement.getBoundingClientRect()
    var myElementHeight = myElement.offsetHeight
    var myElementWidth = myElement.offsetWidth
    let showMobileFooter
    if (
      bounding.top >= -myElementHeight &&
      bounding.left >= -myElementWidth &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth) +
          myElementWidth &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) +
          myElementHeight
    ) {
      showMobileFooter = false
    } else if (window.scrollY > 400) {
      showMobileFooter = true
    }

    this.setState({ showMobileFooter: showMobileFooter })
  }

  handleScroll(event) {
    event.preventDefault()

    if (window.scrollY === 0) {
      document.body.classList.toggle('is-scrolling', false)
    } else {
      document.body.classList.toggle('is-scrolling', true)
    }
    this.elementInViewport()
  }

  onLangChange = lang => {
    let currentPathname = window.location.pathname
      .replace(/\/+$/, '')
      .split('/')
      .filter(x => {
        return x !== ''
      })

    const hasLocale = currentPathname.filter(value =>
      ['en', 'ar', 'es'].includes(value)
    )

    if (hasLocale.length) {
      const index = currentPathname.indexOf(hasLocale[0])
      currentPathname[index] = lang
      const newPath = currentPathname.join('/')
      window.location.replace(`/${newPath}`)
    } else {
      const newPath = [lang, ...currentPathname].join('/')
      window.location.replace(`/${newPath}`)
    }
  }

  renderLangButton() {
    const { t } = this.props

    return (
      <div>
        <ul className="px-0">
          <div className="dropdown">
            <button
              className="dropdown-toggle nav-link btn u-bg-transparent text-right"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p className="h5">
                {t('Language')} <i className="fa fa-globe px-2" />
                <i className="fa fa-angle-down text-left"></i>
              </p>
            </button>
            <div
              className="dropdown-menu box-shadow-v2 text-center"
              aria-labelledby="navbarDropdown"
            >
              <button
                className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent dropdown-item my-3 h5"
                onClick={() => this.onLangChange('en')}
              >
                {t('English')}
              </button>
              <button
                className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent dropdown-item my-3 h5"
                onClick={() => this.onLangChange('ar')}
              >
                {t('Arabic')}
              </button>
              <button
                className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent dropdown-item my-3 h5"
                onClick={() => this.onLangChange('es')}
              >
                {t('Spanish')}
              </button>
            </div>
          </div>
        </ul>
      </div>
    )
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  onFeaturesClick() {
    this.props.onFeaturesClick()
    this.toggleNavbar()
  }

  onHardwareClick() {
    this.props.onHardwareClick()
    this.toggleNavbar()
  }

  onHomeClick() {
    if (this.props.onHomeClick) {
      this.toggleNavbar()
      return this.props.onHomeClick()
    }
    this.props.history.push('/')
    return this.toggleNavbar()
  }

  onRequestDemoClick() {
    const { i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }
    window.open(`https://home.freshsuq.com/${locale}/setup`)
    if (window.analytics) {
      window.analytics.track(`Navbar Clicked on Try Demo`)
    }

    this.toggleNavbar()
  }

  onDemoClick() {
    const { i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `${i18n.language.toString()}`
    }
    window.open(`https://home.freshsuq.com/${locale}/setup`)
    if (window.analytics) {
      window.analytics.track(`Navbar Clicked on Try Demo`)
    }

    this.toggleNavbar()
  }

  showLinks() {
    const { homeOnly, t, i18n } = this.props
    let locale = 'en'
    if (i18n.languages.includes(i18n.language)) {
      locale = `/${i18n.language.toString()}`
    }

    if (homeOnly) {
      return (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              href={`${locale}`}
              className="nav-link btn mt-lg-1 mt-md-1 text-left"
              onClick={() => this.onHomeClick()}
            >
              <p className="h5">{t('Home')}</p>
            </a>
          </li>
        </ul>
      )
    }

    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a href={`${locale}`} className="nav-link btn text-left">
            <p className="h5">{t('Home')}</p>
          </a>
        </li>

        <li className="nav-item dropdown">
          <a
            href="/#"
            className="dropdown-toggle nav-link btn text-left"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <p className="h5">
              {t('BuseinssTypes')}{' '}
              <i className="fa fa-angle-down text-left"></i>
            </p>
          </a>
          <div
            className="dropdown-menu box-shadow-v2 text-center"
            aria-labelledby="navbarDropdown"
          >
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/retail/grocery-store`}
            >
              {t('groceryStores')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/retail/retail`}
            >
              {t('Retail')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/restaurant`}
            >
              {t('restaurants')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/coffee-shop/`}
            >
              {t('coffeeShop')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/quick-service/`}
            >
              {t('quickService')}
            </a>
            <a
              className="dropdown-item my-3 h5"
              href={`${locale}/restaurants/food-truck/`}
            >
              {t('foodTrucks')}
            </a>
          </div>
        </li>

        <li className="nav-item">
          <a href={`${locale}/pricing`} className="nav-link btn text-left">
            <p className="h5">{t('Pricing')}</p>
          </a>
        </li>

        <li className="nav-item">
          <div>{this.renderLangButton()}</div>
        </li>

        <li className="nav-item mt-3 mt-lg-5 mt-xl-0">
          <button
            onClick={() => this.onDemoClick()}
            type="button"
            className="btn btn-black text-center p-1 pl-4 pr-4 u-fs-22 w-100 w-lg-auto"
          >
            <p className="text-center text-white mb-0">{t('tryNow')}</p>
          </button>
        </li>
      </ul>
    )
  }

  render() {
    const { ShowToggle, showWhiteLogo, i18n, t } = this.props
    const { collapsed, showMobileFooter } = this.state

    let locale = ''
    if (i18n.languages.includes(i18n.language)) {
      locale = `/${i18n.language.toString()}`
    }

    let mobileFooter = showMobileFooter ? '' : 'd-none'

    return (
      <div className="maxWidth">
        <header className="sticky-top">
          <div className="container RadiusHeader">
            <nav className="navbar navbar-expand-xl navbar-light p-1">
              <Link className="navbar-brand" to={`${locale}`}>
                <span className="logo-inverse d-flex align-items-center">
                  <img
                    width={175}
                    height={'auto'}
                    src={Images.freshly_logo}
                    alt="FreshSUQ"
                  />
                  {/*<p className="mb-0 ml-2 h3 u-fw-600 icon_spacing">Freshly</p>*/}
                </span>
              </Link>

              {ShowToggle && (
                <NavbarToggle
                  t={t}
                  collapsed={collapsed}
                  onClick={this.toggleNavbar}
                />
              )}

              <div
                className={
                  'collapse navbar-collapse ' + (collapsed ? '' : 'show')
                }
                id="navbarNav"
              >
                {this.showLinks()}
              </div>
            </nav>
          </div>
          <MobileView>
            <div className={`${mobileFooter} mobileFooter`}>
              <div className="px-2">
                <MobileTryButton props={this.props} />
              </div>
            </div>
          </MobileView>
        </header>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    navOpen: state.nav.open
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header))
)
