const Images = {
  logo: require('../images/logo_black.svg').default,
  freshlyicon: require('../images/freshlyicon.svg').default,
  logoWhite: require('../images/logo_white.svg').default,
  freshly_logo: require('../images/freshly_logo.svg').default,
  freshly_white_logo: require('../images/freshly_white_logo.svg').default,
  dashboard_en: require('../images/dashboard_en.webp'),
  ios: require('../images/ios.svg').default,
  android: require('../images/android.svg').default,
  model_scan_its: require('../images/model_scan_its.webp'),
  product_connect: require('../images/product_connect.webp'),
  inventory_ordering: require('../images/inventory_ordering.png'),
  model_print_receipts: require('../images/model_print_receipts.webp'),
  model_manage_discounts: require('../images/model_manage_discounts.webp'),
  model_multiple_locations: require('../images/model_multiple_locations.webp'),
  hero_image: require('../images/hero_image.png'),
  app_store: require('../images/app_store.webp'),
  play_store: require('../images/play_store.webp'),
  client_photo: require('../images/client_photo.webp'),
  client_female_photo: require('../images/client_female_photo.webp'),
  laptop: require('../images/laptop.webp'),
  model_sales_report: require('../images/model_sales_report.webp'),
  model_sales_trend_analysis: require('../images/model_sales_trend_analysis.webp'),
  model_business_expense_report: require('../images/model_business_expense_report.webp'),
  model_complete_reports: require('../images/model_complete_reports.webp'),
  model_employee_shift: require('../images/model_employee_shift.webp'),
  model_employee_access_rights: require('../images/model_employee_access_rights.webp'),
  model_employee_performance: require('../images/model_employee_performance.webp'),
  model_easy_inventory_setup: require('../images/model_easy_inventory_setup.webp'),
  model_unlimited_stock: require('../images/model_unlimited_stock.webp'),
  model_stock_alert: require('../images/model_stock_alert.webp'),
  model_purchase_orders: require('../images/model_purchase_orders.webp'),
  model_inventory_count: require('../images/model_inventory_count.webp'),
  model_easily_manage_your_customers: require('../images/model_easily_manage_your_customers.webp'),
  model_house_account: require('../images/model_house_account.webp'),
  model_loyalty_program: require('../images/model_loyalty_program.webp'),
  model_works_offline: require('../images/model_works_offline.webp'),
  model_convenient_to_customers: require('../images/model_convenient_to_customers.webp'),
  ipad_device: require('../images/ipad_device.webp'),
  cards: require('../images/cards.webp'),
  pos_computer: require('../images/pos_computer.webp'),
  sales_report: require('../images/sales_report.webp'),
  payments_report: require('../images/payments_report.webp'),
  tax_report: require('../images/tax_report.webp'),
  inventoryApp: require('../images/inventoryApp.webp'),
  tablesManagement: require('../images/tablesManagement.png'),
  KDSIpad: require('../images/KDSIpad.webp'),
  ipad_restaurant: require('../images/ipad_restaurant.webp'),
  pos_grocery: require('../images/pos.webp'),
  cdsIPAD: require('../images/cdsIPAD.png'),
  zakatLogo: require('../images/zakat.svg').default
}

export default Images
