import React, { useEffect, useState } from 'react'

const English = () => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await fetch(
          'https://freshlycart-static.s3.us-east-1.amazonaws.com/privacy-policy-en.html'
        )

        const text = await response.text()
        setHtmlContent(text)
      } catch (error) {
        console.error('Error fetching privacy policy:', error)
      }
    }

    fetchPrivacyPolicy()
  }, [])

  return (
    <section className="u-py-100 mt-5 u-pb-lg-150 u-flex-center">
      <div className="container">
        <div className="row justify-content-center box-shadow-v1 u-py-40">
          <div className="col-9 align-text-left">
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default English
