export default {
  translations: {
    approvedBy: 'Approved By',
    home: {
      scanIt: 'Scan Your Products',
      scanItSubtitle:
        "Freshly point of sale system makes it simple to sell. Use your barcode scanner to add products to the customer's orders.",
      PrintReceipts: 'Print Your Receipts',
      PrintReceiptsSubtitle:
        'Always print receipts. Freshly makes it easy for you to follow government regulations to give out printed receipts',
      ManageInventory: 'Manage Inventory',
      ManageInventorySubtitle:
        'Freshly makes it easy to add and manage your products in your inventory. Use Freshly free inventory application to quickly manage your stock',
      MultipleLocations: 'Multiple Locations',
      MultipleLocationsSubtitle:
        'Manage your locations and sales in real-time. Freshly allows you to handle all products, inventory, customers, and sales from one location',
      InventoryManagmentTitle: 'Easy Inventory Management',
      InventoryManagment1:
        'Track stock levels in real-time to avoid overstocking or running out.',
      InventoryManagment2:
        'Set automatic low-stock alerts to stay ahead of demand.',
      InventoryManagment3:
        'Simplify your inventory process with an intuitive, easy-to-use system.',
      SalesAnalyticsTitle: 'Real-Time Sales Analytics',
      SalesAnalytics1:
        'Access live sales data to make informed decisions instantly.',
      SalesAnalytics2: 'Monitor revenue and sales metrics from any device.',
      SalesAnalytics3:
        'Gain insights that help you optimize operations and boost profits.',
      LoyaltyProgramTitle: 'Loyalty Program',
      LoyaltyProgram1:
        'Reward customers with points, discounts, or exclusive offers.',
      LoyaltyProgram2:
        'Track customer purchases to create personalized rewards.',
      LoyaltyProgram3:
        'Strengthen relationships and improve customer retention.'
    },
    inventory: {
      InventorySetup: 'Inventory Setup',
      InventorySetupSubtitle:
        'Freshly makes setting up your inventory easy. Our inventory application for mobile phones makes adding and managing products from one location easy and quick.',
      UnlimitedStock: 'Unlimited Stock',
      UnlimitedStockSubtitle:
        'Manage every product in your inventory. With unlimited stock, you can import items individually or in bulk at any time.',
      StockAlert: 'Stock Alert',
      StockAlertSubtitle:
        "Monitor your product's stock levels easily with Freshly. Receive a notification, when any product reaches its minimum quantity level. Never have products run out of stock.",
      PurchaseOrders: 'Purchase orders',
      PurchaseOrdersSubtitle:
        'Restock at the right time with Freshly. Generate purchase orders for any vendor directly from the system.',
      InventoryCount: 'Inventory count',
      InventoryCountSubtitle:
        'Always know how your business is running. Use the inventory application to count every product in your inventory and their stock levels. You will never need to use a pen and paper to count your inventory.'
    },
    BusinessReports: {
      SalesReport: 'Sales report',
      SalesReportSubtitle:
        'Utilize your real-time sales, inventory and customer data to guide your business to more profitability. Gain insights into your best selling items and their stock levels',
      ExpenseReports: 'Expense Reports',
      ExpenseReportsSubtitle:
        'We have the analytics that will help you quickly understand where your expenses coming from. Increase your profit by reducing your expenses as much as possible.',
      CompleteReports: 'Complete Reports',
      CompleteReportsSubtitle:
        'Grow your business with a powerful reporting engine. You will never have to guess your numbers. Get access to key metrics that matter to you from anywhere.'
    },
    CustomerManagement: {
      ManageCustomers: 'Manage Your Customers',
      ManageCustomersSubtitle:
        'Save time when Building your customer database by adding your customers directly at the point of sale. Manage your customer profiles during transactions.',
      HouseAccount: 'House Account',
      HouseAccountSubtitle:
        "Our house account feature allows you to keep track of your customer's account receivable. With a simplified checkout. You will never miss a payment after today.",
      LoyaltyProgram: 'Loyalty Program',
      LoyaltyProgramSubtitle:
        'Grow your sales and customer retention by rewarding customers for repeat business. Customize your own loyalty rules to allow customers to earn points.'
    },
    EmployeeManagement: {
      EmployeeShift: 'Employee Shift',
      EmployeeShiftSubtitle:
        'Manage all aspects of your employees with Freshly. Employees can clock in with their PIN code at the beginning of the shift and the system will keep track of their time.',
      EmployeeRights: 'Access Rights',
      EmployeeRightsSubtitle:
        'Protect your business with Freshly. All your employees will have will be required to use their own password to access the point of sale system. You can assign permissions to each one of them.',
      EmployeePerformance: 'Employee Performance',
      EmployeePerformanceSubtitle:
        "Get a breakdown reports of each one of your employees. Know their working hours, or their sales performance. Keep track of your employees' order history."
    },
    CustomerDisplay: {
      ConvenientForCustomers: 'Convenient for customers',
      ConvenientForCustomersSubtitle:
        'Improve the sales experience for your customers and employees through our customer display system. Allow your customers to see their order information before making their final payment.',
      WorksOffline: 'Works offline',
      WorksOfflineSubtitle:
        'You do not need to connect Freshly customer Display system to the internet. It works on a separate tablet from your point of sales system. '
    },
    pricing: {
      title:
        "Freshly works with stores of all kinds and sizes and we understand that every business has it's unique needs"
    },
    description:
      'Freshly POS & Cashier application designed for restaurants and retail stores, with tools for inventory, sales, customer, and more. Works on Android and iPad',
    tryItForFree: 'Try Freshly Cart now without a contract',
    tryItForFreeSubtitle:
      'Download Freshly Cart now and start selling within minutes',
    ProfessionalServices: 'Professional Service',
    ProfessionalServicesSubtitle:
      'Team of specialists is ready and waiting to help you get started with Freshly. Our technical support team is ready to assist every single one of our customers. We are just a phone call away',
    Pricing: 'Pricing',
    ReportsSubHeader:
      'You can track all types of sales from anywhere. Get detailed reports that prevent you from visiting the store daily for follow-up',
    StockSubtitle:
      'Freshly helps you keep track of available inventory in your store. In addition, you will get an alert if inventory runs low',
    CustomerManagementSubtitle:
      'Your customers are your success, with Freshly you can save their information and communicate with them to increase your sales in times of deals',
    'Spend less time on repetitive tasks so you can focus on doing what you love':
      'Spend less time on repetitive tasks so you can focus on doing what you love.',
    title: 'Freshly Cart POS | iPad And Android POS System',
    TitlePart: ' | Freshly Cart POS',
    InventoryTitle: 'Inventory Management | Freshly Cart POS',
    ReportsTitle: 'Business Reports | Freshly Cart POS',
    CustomerManagementTitle: 'Customer Management | Freshly Cart POS',
    EmployeeManagementTitle: 'Employees Management | Freshly Cart POS',
    CustomerDisplayTitle: 'Customer Display | Freshly Cart POS',
    HardwareTitle: 'Hardware | Freshly Cart POS',
    PricingTitle: 'Pricing | Freshly Cart POS',
    RetailTitle: 'Retail | Freshly Cart POS',
    GroceryStoreTitle: 'Grocery Store | Freshly Cart POS',
    RestaurantsTitle: 'Restaurants | Freshly Cart POS',
    SupportTitle: 'Support | Freshly Cart POS',
    'Learn More': 'Learn More',
    'Get Free Demo': 'Get Free Demo',
    'Get Your Free Demo': 'Get Your Free Demo',
    requestDemo: 'Get Demo',
    YourDemoContains:
      'This demo contains a training course for Freshly applications',
    CompleteThisForm: 'Complete this form to receive a free demo',
    'Everything For Your Business': 'Flexible Point of Sale System',
    'Simplifies running your business, by allowing you to streamline your orders':
      'Designed to make your operations faster. Manage your restaurant or store from anywhere on Android or iPad',
    'We will be in contact soon': 'We will be in contact soon',
    'Discover how to improve your business with us':
      'Discover how to improve your business with Freshly',
    'Your full name': 'Your full name',
    'Your Phone number': 'Your Phone number',
    'Your email': 'Your email',
    'Select Country': 'Select Country',
    SelectPhoneCode: 'Area code',
    'You must write a correct e-mail': 'You must write a correct e-mail',
    Submit: 'Submit',
    'Grocery Store': 'Grocery Store',
    Retail: 'Retail',
    'Food Truck': 'Food Truck',
    'Coffee Shop': 'Coffee Shop',
    Arabic: 'Arabic',
    English: 'English',
    Spanish: 'Spanish',
    Language: 'Language',
    Bakary: 'Bakary',
    '+More': '+More',
    'Your Store Deserves Better System': 'Your Store Deserves Better System',
    'No problem is too small':
      'No problem is too small for our support experts. Available throughout the year to answer your call, email, or web chat',
    'Remote Support 24/7': 'Remote Support 24/7',
    'Employee Training': 'Employee Training',
    'Learn the system':
      'Learn the system and train your staff quickly. A dedicated team member will work with you to customize your configurations',
    'Multi Language Support': 'Multi Language Support',
    'Your system supports a variety of languages':
      'Your system supports a variety of languages, starting with Arabic and English. Each employee should have their individual language choice when using the system',
    'Powerful Features That Go the Distance':
      'Powerful Features That Go the Distance',
    'Delivery Management': 'Delivery Management',
    'Manage your orders':
      'Manage your orders and delivery timelines with a better and faster delivery system. Assign orders to drivers and keep generate proof of delivery log',
    'Offline Mode Capability': 'Offline Mode Capability',
    'Internet connectivity issues, keep using your system and all your data and work is automatically synced to the cloud once you are back online':
      'Internet connectivity issues, keep using your system and all your data and work is automatically synced to the cloud once you are back online',
    'Employee Management': 'Employee Management',
    'Use our multi-level':
      'Use our multi-level access control to manage your employees access. Manage employees timesheet, tasks, and performance',
    'Customer Display Screen': 'Customer Display Screen',
    'Improve your customer experience':
      "Improve your customers' experience with a customer display screen",
    LowInventoryNotification: 'Inventory Notification',
    LowInventoryMsg:
      'Receive automatic alerts when your inventory levels drop below the thresholds you set.',
    'Regular System Updates': 'Regular System Updates',
    'Updates allows your store to take advantage of newer features that have been recently introduced to the platform, with no cost to you':
      'Updates allows your store to take advantage of newer features that have been recently introduced to the platform, with no cost to you',
    'Automate Your Business': 'Automate Your Business',
    'Control your business':
      'Control your business within one platform. Managing your employees and track your inventory becomes simple with Freshly. Use your dashboard or management console to streamline all your business processes',
    'Transform your tablet into an intelligent point of sale machine':
      'Transform your tablet into an intelligent point of sale machine',
    'Inventory App': 'Inventory App',
    'Manage your inventory':
      'Manage your inventory with our inventory app. Use the app to take your physical inventory count, add or remove items from your inventory',
    'Data Security': 'Data Security',
    'We protect your sensitive business data and your customers’ information from potential threats with our reliable and secure platform':
      'We protect your sensitive business data and your customers’ information from potential threats with our reliable and secure platform',
    'Third Party Integration': 'Third Party Integration',
    'Integrate third party apps directly':
      'Integrate third party apps directly into your system. Get more capabilities without incurring extra operational costs!',
    'Real-time Analytics': 'Real-time Analytics',
    'Utilize your real-time sales':
      'Utilize your real-time sales, inventory, or customer data to guide your business to more profitability. Gain insights into your best selling items and their stock levels. Generate reports to help you manage your employees and their productivity',
    Home: 'Home',
    Features: 'Features',
    Hardware: 'Hardware',
    Price: 'Price',
    'Manage Your Employees Easily with FreshSUQ':
      'Manage Your Employees Easily with Freshly',
    'Build a better relationship with your customers':
      'Build a better relationship with your customers',
    'Reports to help you take better control of your business':
      'Reports to help you take better control of your business',
    'Best Inventory Management to Increase Your Profit':
      'Best Inventory Management to Increase Your Profit',
    'Inventory Management': 'Inventory Management',
    'Basic Inventory Management': 'Basic Inventory Management',
    'Basic Features': 'Basic',
    Advanced: 'Advanced',
    'Advaned Inventory Management': 'Advaned Inventory Management',
    Annually: 'Annually',
    Annual: 'Year',
    Monthly: 'Monthly',
    'Annual payment': 'The total amount is paid annually',
    'Monthly payment': 'The amount is paid monthly',
    'Multiple locations': 'Locations',
    'one license': 'Registers',
    'Manage your inventory in real-time':
      'Manage your inventory in real-time, Track your items quantity you have in stock. Get alerts when items are running low. Organize your inventory by category',
    'Reporting and Analytics': 'Reporting and Analytics',
    'Organizes all your data into a clear, friendly, real-time reports, accessible from your desktop or smartphone  Make informed decisions with customized reports':
      'Organizes all your data into a clear, friendly, real-time reports, accessible from your desktop or smartphone. Make informed decisions with customized reports',
    'Customer management': 'Customer management',
    'Build your customer database, Create customer profiles, and search your customers order history and personal contact information':
      'Build your customer database, Create customer profiles, and search your customers order history and personal contact information',
    Company: 'Company',
    'About Us': 'About Us',
    Support: 'Support',
    Terms: 'Terms',
    Privacy: 'Privacy',
    'Contact Info': 'Contact Info',
    'Copyright 2020 FreshSUQ systems': 'Copyright 2025 Freshly systems',
    'We work hard to help you get the most out of your business':
      'We work hard to help you get the most out of your business',
    'At FreshSUQ, we’re passionate about providing a hassle-free platform for business owners':
      'At Freshly, we’re passionate about providing a hassle-free platform for business owners',
    'We focus on creating solutions for those who need help improving their business workflow and reducing their costs':
      'We focus on creating solutions for those who need help improving their business workflow and reducing their costs',
    'We are truly excited to belong to a community of business owners who are eager to optimize their business':
      'We are truly excited to belong to a community of business owners who are eager to optimize their business',
    'success is defined by our customers':
      "Our company's success is defined by our customers’ satisfaction, this is why we work hard on the quality of Freshly. It’s our mission to help you simplify your business needs, and guide you to more profitability",
    'Fair & Simple Pricing': 'Simple Pricing. Best Value',
    '24/7 Support': '24/7 Support',
    Products: 'Products',
    Employees: 'Employees',
    Unlimited: 'Unlimited',
    'Users & Customers': 'Users & Customers',
    'Secure cloud backup': 'Secure cloud backup',
    'Purchase orders': 'Purchase orders',
    'Customer display': 'Customer display',
    'Employee timesheet': 'Employee timesheet',
    'Reports & analytics': 'Reports & analytics',
    'Delivery log & history': 'Delivery log & history',
    'QuickBooks / xero Integration': 'QuickBooks / xero Integration',
    'Dedicated account manager': 'Dedicated account manager',
    'Third party integration': 'Third party integration',
    'Customized onboarding': 'Customised onboarding',
    'Extra Location': 'Extra Location',
    'Extra Register': 'Extra Register',
    'Extra Employee': 'Extra Employee',
    'Employee Management': 'Employee Management',
    'Add-ons': 'Add-ons',
    month: 'Month',
    Free: 'Free',
    Basic: 'Basic',
    Plus: 'Plus',
    Pro: 'Pro',
    Select: 'Select',
    Recommended: 'Recommended',
    'We will reach out to you very soon': 'We will reach out to you very soon',
    'Saudi Arabia': 'Saudi Arabia',
    'United Arab Emirates': 'United Arab Emirates',
    Qatar: 'Qatar',
    Bahrain: 'Bahrain',
    Egypt: 'Egypt',
    Oman: 'Oman',
    Jordan: 'Jordan',
    Lebanon: 'Lebanon',
    'United States': 'United States',
    Support_msg1:
      "We're working on building a comprehensive technecal support portal",
    Support_msg2: 'if you have any support question please email us at',
    'Free Trial': 'Start Now',
    'Try Trial': 'Start Now',
    pricing_header: '45 day FREE trial with all plans!',
    pricing_subheader:
      'Our pricing is simple and you can cancel or change your plan at any time.',
    hardware: 'Hardware',
    hardware_list: 'Hardware',
    tablet_stand: 'Tablet Stand',
    tablet_stand_msg:
      'Tablet Holder with security lock. Fits all tablets from 7.9 to 12.9 inch',
    barcode_scanner: 'Barcode Scanner',
    barcode_scanner_body:
      'Bluetooth barcode scanner, works with IOS and Android',
    SAR: 'SAR',
    HardwareFor: 'Hardware for Freshly Cart POS',
    ForSupportedList:
      'For a list of Freshly supported hardware, please email us at ',
    allRights: 'Freshly 2025 All Rights Reserved',
    pricesSubjectToChange: 'Prices are subject to change without notice',
    LimitedTime: 'Limited Time Offer',
    Menu: 'Menu',
    Faq: 'Frequently Asked Questions',
    HowToUseFreshSUQ: 'How do I use Freshly application?',
    HowToUseFreshSUQMsg:
      'Start by downloading the app on Apple store or Google play store. Create your account on the app or the management console. Then add your items by either using the inventory app or on the management console. You can edit your account information, add taxes and discounts on the management console.',
    DoesFreshsuqOffline: 'Does Freshly application work offline?',
    DoesFreshsuqOfflineMsg:
      'Freshly works offline. You can make sales, add new items and discounts without internet connection. However, to upload your sales and the new items or discounts to the management console you will have to connect to the internet to sync your data with the management console',
    WhatDevicesWorkWithFreshsuq:
      'What kind of devices work with Freshly application?',
    WhatDevicesWorkWithFreshsuqMsg:
      'Freshly application works on IPad and any Android tablet. You can connect any bluetooth barcode scanner, receipt printer we suggest Epson network receipt printers and any cash drawer. If your hardware is not supported please email us and we will add it to Freshly supported devices.',
    HowToSignup: 'How can I sign-up with Freshly?',
    HowToSignupMsg:
      'You can register by clicking on Start with Freshly and filling the form or you can download Freshly application and complete the registration questions on the application.',
    SignUpForFree: 'Register Now for Free',
    POSSectionTitle: 'One App To Manage all Your Store needs',
    POSSectionSubTitle:
      'We know regulations are constantly changing. But we have all the options to run your entire store',
    HowItWorks: 'How It Works',
    InventorySectionTitle: 'Inventory Application',
    InventorySectionSubTitle:
      'Use your phone as a handheld barcode scanner to add or update products',
    AddNewProducts: 'Add New Products',
    AddNewProductsMsg:
      'Easily add and manage your inventory with Freshly. Add products directly via the management console or inventory app.',
    SalesReports: 'Sales Reports',
    SalesReportsMsg:
      'Get a better look at your store’s  daily transactions. A detailed look at purchases and returns listed by the day. It helps you optimize your staffing and marketing choices',
    TaxReports: 'Tax Reports',
    TaxReportsMsg:
      "With Freshly you don't need another software to calculate your tax obligations. Freshly is fully tax compliant and generates reports you can use to file your taxes",
    PaymentsReports: 'Payment Reports',
    PaymentsReportsMsg:
      'Payment report give you a closer look at your customers’ preferred payment methods. Get a detailed list of cash and card payments totals and refunds',
    Reports: 'Reports',
    ReportsSubsection:
      'Reports that show you all details of your store - inventory, sales, customers and employees',
    BuseinssTypes: 'Buseinss Types',
    groceryStores: 'Grocery Stores',
    Barbershop: 'Barbershop',
    restaurants: 'Restaurants',
    coffeeShop: 'Coffee Shop',
    quickService: 'Quick Service',
    foodTrucks: 'Food Truck',
    forRetails: 'For Retail',
    stores: 'Retail Store',
    forRestaurants: 'For Restaurants',
    groceryStoresSubtitle:
      'Designed completely for managing grocery stores and supermarkets. Contains over 100K items ready to be add to your inventory',
    retailStoresSubtitle:
      'Flexible system designed for retailers. Contains all the tools you need to run and manage your store and inventory from anywhere',
    restaurantSSubtitle:
      'Complete system designed for restaurant management. Use the right tools to run your restaurant more efficiently and serve your customer better',
    coffeeShopSubtitle:
      'Flexible system designed for coffee shop management. Contains all the tools to serve your customers better',
    quickServiceSubtitle:
      'Flexible system designed for quick services. Allows you to add orders faster and keep your staff efficient',
    foodTrucksSubtitle:
      'Flexible system designed for food trucks. Allows you to add orders faster and keep your staff organized',
    kds: 'Kitchen Display Screen',
    kdsSubtitle:
      'Improve orders accuracy and increase efficiency. The Kitchen Display system allows you to speed up service with on-screen tickets to reduce long wait times and missed orders',
    tablesManagement: 'Table Management',
    tablesManagementSub:
      'The table management system makes it easy to design your floor plan and rearrange it easily. Take orders by table, and manage your restaurant capacity',
    inventorySubheader:
      'Inventory is a the most important aspect of your business that has the ability to increase your profit. We built an application dedicated to managing your entire inventory and make the inventory process a lot easier',
    groceryStoreText:
      'We know your point of sale system is the most important software in your store. That is why we designed our system to work even if the internet is disconnected.',
    restaurantsText:
      'Manage orders, sales, and payments in one place with a secure cloud-based system. With all the technology you need to run your restaurant in one easy platform.',
    coffeeShopText:
      'Manage orders, sales, and payments in one place with a secure cloud-based system. With all the technology you need to run your coffee shop in one easy platform.',
    howToDoIt: 'How To Do It',
    changeHere: 'Edit Here',
    gettingStartedTitle: 'Getting Started',
    gettingStartedTitlePage: 'Freshly Cart POS | Getting Started',
    gettingStartedTitleBody:
      'This list will help you set up Freshly Cart POS for your store. Follow the steps below to get started',
    generalSettings: 'General Settings ',
    generalSettingsSteps: {
      vat_id:
        'Set up the store general information including VAT ID if required',
      store: 'Set up the store location information',
      taxes: "Set up your store taxes if it's required",
      employees: 'Add your employees to the system'
    },
    InventorySetup: 'Inventory Setup',
    InventorySetupSteps: {
      add_categories: 'Add product categories as you need',
      add_products: 'Add products individually or in bulk'
    },
    HardwareSetup: 'Hardware Setup',
    tryNow: 'Try Now',
    numberOfLocations: 'Number of Locations',
    numberOfRegisters: 'Number of Registers',
    extraLocations: 'Extra Locations',
    extraRegisters: 'Extra Registers',
    basePlanCost: 'Base Plan Cost',
    discount: 'Discount',
    firstMonthFree: 'Start now with a 30 day free trial',
    creditCardNotReqired: 'Credit card not required',
    seePricing: 'Show Pricing',
    subscribeAndUseNow: 'Join now and start with Freshly in minutes',
    HardwareSetupSteps: {
      list_of_hardware:
        'List of hardware you need to work with Freshly Cart POS',
      tablet: 'Tablet, IPad or Android',
      wifi_router: "Internet wifi router 'to connect to the ethernet printer'",
      receipt_printer: 'Receipt printer with ethernet port',
      cash_drawer: "Cash drawer 'optional'",
      barcode_scanner: "Bluetooth barcode scanner 'optional'",
      download_app:
        'Download Freshly Cart pos on your tablet from App Store or Play Store',
      register:
        'Register or sign in on the POS app using your email and password',
      connect: 'Connect your tablet and printer to the same router',
      connect_tablet: 'Connect the tablet to the wifi network',
      connect_printer:
        'Connect your printer to the wifi router using the ethernet cable',
      set_printer: 'Set up your printer',
      set_barcode: 'Setup your barcode scanner'
    }
  }
}
