import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Footer } from '../components/partials'
import { MiniHeader } from '../components/navbar'

import HomePage from '../containers/HomePage'
import PricingPage from '../containers/PricingPage'
import TermsPage from '../containers/TermsPage'
import AboutUsPage from '../containers/AboutUsPage'
import PrivacyPage from '../containers/PrivacyPage'
import SupportPage from '../containers/SupportPage'
import RetailLandingPage from '../containers/RetailLandingPage'
import HardwarePage from '../containers/HardwarePage'

import FeaturesPage from '../containers/FeaturesPage'
import InventoryManagementPage from '../containers/features/InventoryManagementPage'
import BusinessReportsPage from '../containers/features/BusinessReportsPage'
import CustomerManagementPage from '../containers/features/CustomerManagementPage'
import EmployeeManagementPage from '../containers/features/EmployeeManagementPage'
import CustomerDisplayPage from '../containers/features/CustomerDisplayPage'
import RequestDemoPage from '../containers/RequestDemoPage'

import GroceryStorePage from '../containers/businessTypes/GroceryStorePage'
import RetailStorePage from '../containers/businessTypes/RetailStorePage'
import RestaurantPage from '../containers/businessTypes/RestaurantPage'
import CoffeeShopPage from '../containers/businessTypes/CoffeeShopPage'
import QuickServicePage from '../containers/businessTypes/QuickServicePage'
import FoodTruckPage from '../containers/businessTypes/FoodTruckPage'
import GettingStartedPage from '../containers/GettingStartedPage'

const base = '/:locale(en|ar|es)?'

const English = () => (
  <div>
    <Switch>
      <Route exact path={base} component={HomePage} />
      <Route exact path={`${base}/features`} component={FeaturesPage} />
      <Route
        exact
        path={`${base}/features/inventory_management`}
        component={InventoryManagementPage}
      />
      <Route
        exact
        path={`${base}/features/business_reports`}
        component={BusinessReportsPage}
      />
      <Route
        exact
        path={`${base}/features/customer_management`}
        component={CustomerManagementPage}
      />
      <Route
        exact
        path={`${base}/features/employee_management`}
        component={EmployeeManagementPage}
      />
      <Route
        exact
        path={`${base}/features/customer_display`}
        component={CustomerDisplayPage}
      />

      <Route
        exact
        path={`${base}/retail/grocery-store`}
        component={GroceryStorePage}
      />
      <Route exact path={`${base}/retail/retail`} component={RetailStorePage} />
      <Route
        exact
        path={`${base}/restaurants/restaurant`}
        component={RestaurantPage}
      />
      <Route
        exact
        path={`${base}/restaurants/coffee-shop`}
        component={CoffeeShopPage}
      />
      <Route
        exact
        path={`${base}/restaurants/quick-service`}
        component={QuickServicePage}
      />
      <Route
        exact
        path={`${base}/restaurants/food-truck`}
        component={FoodTruckPage}
      />

      <Route
        exact
        path={`${base}/getting-started`}
        component={GettingStartedPage}
      />
      <Route exact path={`${base}/pricing`} component={PricingPage} />
      <Route exact path={`${base}/about`} component={AboutUsPage} />
      <Route exact path={`${base}/terms`} component={TermsPage} />
      <Route exact path={`${base}/privacy`} component={PrivacyPage} />
      <Route exact path={`${base}/support`} component={SupportPage} />
      <Route
        exact
        path={`${base}/marketing/retail`}
        component={RetailLandingPage}
      />
      <Route exact path={`${base}/hardware`} component={HardwarePage} />
      <Route exact path={`${base}/get-free-demo`} component={RequestDemoPage} />
    </Switch>
    <Footer />
  </div>
)

export default English
