import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import English from '../components/terms/English'
import Arabic from '../components/terms/Arabic'
import Spanish from '../components/terms/Spanish'

// components
import { Header } from '../components/navbar'

class TermsPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {}
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  routeTo(path) {
    this.props.history.push(path)
  }

  renderContent = () => {
    const { i18n } = this.props
    const lang = i18n.language

    switch (lang) {
      case 'ar':
        return <Arabic />
      case 'es':
        return <Spanish />
      default:
        return <English />
    }
  }

  render() {
    const { t } = this.props
    document.title = t('title')

    return (
      <div>
        <Header ShowToggle onDemoClick={true} />
        {this.renderContent()}
      </div>
    )
  }
}

export default withTranslation()(TermsPage)
