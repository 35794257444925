import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import PricingService from '../../lib/PricingService'
import FAQ from '../Faq'
import Select from 'react-select'

class Pricing extends Component {
  constructor(props) {
    super(props)
    this.countries = [
      {
        value: 'US',
        label: 'United States',
        currency: 'USD'
      },
      {
        value: 'SA',
        label: 'Saudi Arabia',
        currency: 'SAR'
      },
      {
        value: 'CO',
        label: 'Colombia',
        currency: 'COP'
      }
    ]

    this.state = {
      selectedCountry: 'US',
      currency: 'USD',
      ipCountry: 'US',
      form: {
        interval: 'monthly',
        locations_number: '1',
        registers_number: '1'
      },
      plansCostDetails: new PricingService(
        'monthly',
        PricingService.getCountryByValue('US')
      ).plansCostDetails,
      selectedCountry: this.countries[0]
    }
  }

  componentDidMount() {
    const allowedCountries = ['US', 'SA', 'CO']
    // Fetch the user's country based on IP
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        const userCountry = data.country

        if (allowedCountries.includes(userCountry)) {
          this.setState({ selectedCountry: userCountry })
          this.handleCountryChange({ value: userCountry })
        }
      })
      .catch(error => {
        console.error('Error fetching country data:', error)
      })
  }

  handleCountryChange = selectedCountry => {
    const { form } = this.state
    const country = PricingService.getCountryByValue(selectedCountry.value)
    const plansCostDetails = new PricingService(form['interval'], country)
      .plansCostDetails

    this.setState({ selectedCountry: country, plansCostDetails })
  }

  onIntervalChange = interval => {
    const { form, selectedCountry } = this.state
    form['interval'] = interval
    const country = PricingService.getCountryByValue(selectedCountry.value)
    const plansCostDetails = new PricingService(interval, country)
      .plansCostDetails

    this.setState({ form, plansCostDetails })
  }

  render() {
    const { t, i18n, onOpenModal } = this.props
    const { form, plansCostDetails, selectedCountry } = this.state
    const textDirection = i18n.language === 'ar' ? 'text-right' : 'text-left'
    const monthly = form.interval === 'monthly'
    const annually = form.interval === 'annually'
    const ulDirection = i18n.language === 'ar' ? 'rtl' : 'ltr'
    const selectedPricing = selectedCountry.pricing

    return (
      <section className="pricing py-5 u-h-90vh topSection">
        <div className="container">
          {/* Pricing Controls */}
          <div className="row d-flex justify-content-center">
            <div className="col-md-4 u-mb-30 px-4">
              <p className="text-center">{t('Select Country')}</p>
              <Select
                className="px-3 text-black"
                placeholder={t('Select Country')}
                options={this.countries}
                value={selectedCountry}
                onChange={this.handleCountryChange}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="row d-flex justify-content-center">
              <div className="m-5">
                <ul
                  id="myTab"
                  role="tablist"
                  className="d-flex justify-content-center nav nav-tabs nav-pills flex-column flex-sm-row text-center border-0 rounded-nav"
                >
                  <li className="nav-item flex-sm-fill">
                    <a
                      id="monthly-tab"
                      data-toggle="tab"
                      href="#monthly"
                      target="monthly"
                      role="tab"
                      onClick={() => this.onIntervalChange('monthly')}
                      aria-controls="profile"
                      aria-selected="false"
                      className={`nav-link h5 border-dark px-5 font-weight-bold ${
                        monthly ? 'btn-black' : ''
                      }`}
                    >
                      {t('Monthly')}
                    </a>
                  </li>
                  <li className="nav-item flex-sm-fill">
                    <a
                      id="annually-tab"
                      data-toggle="tab"
                      target="annually"
                      href="#annually"
                      role="tab"
                      onClick={() => this.onIntervalChange('annually')}
                      aria-controls="home"
                      aria-selected="true"
                      className={`nav-link h5 border-dark px-5 mx-lg-2 font-weight-bold ${
                        annually ? 'btn-black' : ''
                      }`}
                    >
                      {t('Annually')}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-12 mx-auto mb-5">
                <div className="">
                  <p className="text-center u-fs-20 text-dark-black pb-0 mb-2">
                    {annually && t('Annual payment')}
                    {monthly && t('Monthly payment')}
                  </p>
                  {annually && (
                    <p className="text-center u-fs-20 text-dark-black">
                      <span className="badge badge-danger p-2">
                        {t('discount')} %20
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row justify-content-center mb-5">
            {/* Basic Plan */}
            <div className="col-lg-4 col-md-12 mb-5 px-0 px-lg-2">
              <div className="borderedCard bg-white">
                <div className="p-4 text-center m-2 bg-white card-shadow">
                  <div className="container p-0 mb-5">
                    <div className="col-md-3 col-lg-7">
                      <p className="bg-primary text-white u-rounded-5 p-1 px-3">
                        {t('Basic')}{' '}
                        <span className="u-fs-19">{t('Free')}</span>
                      </p>
                    </div>
                  </div>

                  <div className="display-4 fw-bold mb-4">
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-8"></div>
                        <div className="col-4">
                          <small className="u-fs-30 fw-normal pr-2 text-muted">
                            {annually ? t('Annual') : t('month')}
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2 text-right">
                          <small className="u-fs-20">
                            {selectedCountry.currency}{' '}
                          </small>
                        </div>
                        <div className="col-10 text-left">
                          <p className="text-left">{plansCostDetails.basic}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-black btn-block btn-lg u-rounded-5 mb-4">
                    {t('Select')}
                  </button>
                </div>

                <div className="mt-5 text-black">
                  <ul className="fa-ul" dir={ulDirection}>
                    <li>
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Multiple locations')}</p>{' '}
                          <p className="font-weight-bold">1</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('one license')}</p>{' '}
                          <p className="font-weight-bold">1</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employees')}</p>{' '}
                          <p className="font-weight-bold">2</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Products')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customers')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory Management')}</p>
                          <p className="font-weight-bold">
                            {t('Basic Features')}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory App')}</p>
                          <p className="font-weight-bold">
                            {t('Basic Features')}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Reports & analytics')}</p>
                          <p className="font-weight-bold">
                            {t('Basic Features')}
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customer display')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employee timesheet')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Delivery log & history')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('CustomerManagement.LoyaltyProgram')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Third party integration')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('tablesManagement')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('kds')}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Advanced Plan */}
            <div className="col-lg-4 col-md-12 mb-5 px-0 px-lg-2">
              <div className="borderedCard bg-white">
                <div className="p-4 text-center m-2 bg-white card-shadow">
                  <div className="container p-0 mb-5">
                    <div className="col-lg-6 col-md-3">
                      <p className="bg-success text-white u-rounded-5 p-1 px-3">
                        {t('Plus')}
                      </p>
                    </div>
                  </div>

                  <div className="display-4 fw-bold mb-4">
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-8"></div>
                        <div className="col-4">
                          <small className="u-fs-30 fw-normal pr-2 text-muted">
                            {annually ? t('Annual') : t('month')}
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2 text-right">
                          <small className="u-fs-20">
                            {selectedCountry.currency}{' '}
                          </small>
                        </div>
                        <div className="col-10 text-left">
                          <p className="text-left">
                            {plansCostDetails.advanced}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-black btn-block btn-lg u-rounded-5 mb-4">
                    {t('Select')}
                  </button>
                </div>

                <div className="mt-5">
                  <ul className="fa-ul text-black" dir={ulDirection}>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Multiple locations')}</p>
                          <p className="font-weight-bold">2</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('one license')}</p>
                          <p className="font-weight-bold">2</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employees')}</p>
                          <p className="font-weight-bold">5</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Products')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customers')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory Management')}</p>
                          <p className="font-weight-bold">{t('Advanced')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory App')}</p>
                          <p className="font-weight-bold">{t('Advanced')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Reports & analytics')}</p>
                          <p className="font-weight-bold">{t('Advanced')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customer display')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employee timesheet')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Delivery log & history')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('CustomerManagement.LoyaltyProgram')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Third party integration')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('tablesManagement')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="text-muted u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa fa-times"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('kds')}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Pro Plan */}
            <div className="col-lg-4 col-md-12 mb-5 px-0 px-lg-2">
              <div className="borderedCard bg-white">
                <div className="p-4 text-center m-2 bg-white card-shadow">
                  <div className="container p-0 mb-5">
                    <div className="col-lg-6 col-md-3">
                      <p className="bg-warning text-white u-rounded-5 p-1 px-3">
                        {t('Pro')}
                      </p>
                    </div>
                  </div>

                  <div className="display-4 fw-bold mb-4">
                    <div className="container p-0">
                      <div className="row">
                        <div className="col-8"></div>
                        <div className="col-4">
                          <small className="u-fs-30 pr-2 fw-normal text-muted">
                            {annually ? t('Annual') : t('month')}
                          </small>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2 text-right">
                          <small className="u-fs-20">
                            {selectedCountry.currency}{' '}
                          </small>
                        </div>
                        <div className="col-10 text-left">
                          <p className="text-left">{plansCostDetails.pro}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button className="btn btn-black btn-block btn-lg u-rounded-5 mb-4">
                    {t('Select')}
                  </button>
                </div>

                <div className="mt-5">
                  <ul className="fa-ul text-black" dir={ulDirection}>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Multiple locations')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('one license')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employees')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Products')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customers')}</p>{' '}
                          <p className="font-weight-bold">{t('Unlimited')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory Management')}</p>
                          <p className="font-weight-bold">{t('Advanced')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory App')}</p>
                          <p className="font-weight-bold">{t('Advanced')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Reports & analytics')}</p>
                          <p className="font-weight-bold">{t('Advanced')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customer display')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employee timesheet')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Delivery log & history')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('CustomerManagement.LoyaltyProgram')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Third party integration')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('tablesManagement')}</p>
                        </div>
                      </div>
                    </li>
                    <li className="u-fs-19">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa text-success fa-check"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('kds')}</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-6 col-md-12 px-0 px-lg-2">
              <div className="h-100 borderedCard px-lg-2 pb-5">
                <div className="p-3 mt-2">
                  <p className="h3 text-black">+ {t('Add-ons')}</p>
                </div>

                <div className="mt-2 text-black">
                  <ul className="fa-ul" dir={ulDirection}>
                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Extra Location')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {plansCostDetails.addons['Extra Location']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>
                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Extra Register')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {plansCostDetails.addons['Extra Register']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Extra Employee')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {plansCostDetails.addons['Extra Employee']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Employee Management')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {plansCostDetails.addons['Employee Management']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Inventory Management')}</p>
                          <span className="font-weight-bold ml-1">
                            {t('Advanced')}
                          </span>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {
                                plansCostDetails.addons[
                                  'Inventory Management (Advanced)'
                                ]
                              }
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Reports & analytics')}</p>
                          <span className="font-weight-bold ml-5">
                            {t('Advanced')}
                          </span>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {
                                plansCostDetails.addons[
                                  'Reports & Analytics (Advanced)'
                                ]
                              }
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('tablesManagement')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {plansCostDetails.addons['Table Management']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('CustomerManagement.LoyaltyProgram')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {plansCostDetails.addons['Loyalty Program']}
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('Customer Display Screen')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {
                                plansCostDetails.addons[
                                  'Customer Display Screen'
                                ]
                              }
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />

                    <li className="u-fs-19 mb-0">
                      <div className="container">
                        <span className="fa-li">
                          <i className="fa u-fs-20 text-success fa-plus"></i>
                        </span>
                        <div className="row justify-content-between mx-2">
                          <p>{t('kds')}</p>
                          <div>
                            <small className="u-fs-17">
                              {selectedCountry.currency}{' '}
                              {
                                plansCostDetails.addons[
                                  'Kitchen Display Screen'
                                ]
                              }
                            </small>
                          </div>
                        </div>
                      </div>
                    </li>

                    <hr className="m-1" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(Pricing)
