import React from 'react'
import { withTranslation } from 'react-i18next'

const MiniHeader = props => {
  function onLangChange(lang) {
    let currentPathname = window.location.pathname
      .replace(/\/+$/, '')
      .split('/')
      .filter(x => {
        return x !== ''
      })

    const hasLocale = currentPathname.filter(value =>
      ['en', 'ar', 'es'].includes(value)
    )

    if (hasLocale.length) {
      const index = currentPathname.indexOf(hasLocale[0])
      currentPathname[index] = lang
      const newPath = currentPathname.join('/')
      window.location.replace(`/${newPath}`)
    } else {
      const newPath = [lang, ...currentPathname].join('/')
      window.location.replace(`/${newPath}`)
    }
  }

  function renderLangButton() {
    const { t } = props

    return (
      <div>
        <ul className="navbar">
          <p className=" nav-item dropdown px-1">
            <a
              href="/#"
              className="dropdown-toggle"
              // role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p className="text-white">
                <i className="fa fa-globe px-2 u-fs-40" />
              </p>
            </a>
            <div
              className="dropdown-menu text-white bg-dark text-center"
              aria-labelledby="navbarDropdown"
            >
              <button
                className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent text-white dropdown-item my-3 h5"
                onClick={() => onLangChange('en')}
              >
                {t('English')}
              </button>
              <button
                className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent text-white dropdown-item my-3 h5"
                onClick={() => onLangChange('ar')}
              >
                {t('Arabic')}
              </button>
              <button
                className="btn btn-sm u-p-5 u-fs-20 u-bg-transparent text-white dropdown-item my-3 h5"
                onClick={() => onLangChange('es')}
              >
                {t('Spanish')}
              </button>
            </div>
          </p>
        </ul>
      </div>
    )
  }

  return (
    <header className="mini-header-top bg-black d-none d-lg-block">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 my-2">
            <ul className="list-inline mb-0 text-white text-center text-md-left">
              <li className="list-inline-item mr-3">
                <span className="fa fa-whatsapp mr-1 text-success"></span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wa.me/+966557585480"
                >
                  +966557585480
                </a>
              </li>
              <li className="list-inline-item">
                <span className="la la-envelope-o mr-1"></span>
                <a href="mailto:support@echotheme.com">sales@freshsuq.com</a>
              </li>
            </ul>
          </div>

          <div className="col-md-6 text-center text-md-right d-none d-lg-block my-2">
            <div className="row align-items-right">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mr-3">{renderLangButton()}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default withTranslation()(MiniHeader)
