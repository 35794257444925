import React from 'react'

const BusinessPageHeader = props => {
  const { i18n } = props
  const lineSpacing = i18n.language === 'ar' ? 'u-lh-1_5' : 'u-lh-1_2'
  const subtTitle =
    props.subtitle || 'الخارجي للنص أو شكل توضع الفقرات في الصفحة التي'

  return (
    <section className="pb-0 pt-lg-4 u-h-90vh mt-5 topSection">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-10 mx-auto">
            <p className="h4 text-black text-center">{props.topTitle}</p>
          </div>
          <div className="col-lg-10 mx-auto u-pt-20 mb-lg-0 mb-2">
            <h1
              className={`display-4 u-fw-600 text-black text-center pt-1 ${lineSpacing} featureHeader`}
            >
              {props.title}
            </h1>
          </div>
          <div className="col-lg-9 mx-auto">
            <p className="u-fs-30 text-black text-center u-lh-1_8 py-4">
              {subtTitle}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center pb-5">
          {props.renderButton}
        </div>
      </div>
    </section>
  )
}

export default BusinessPageHeader
