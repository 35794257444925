export default {
  translations: {
    approvedBy: 'Aprobado Por',
    home: {
      scanIt: 'Escanee Sus Productos',
      scanItSubtitle:
        'El sistema de punto de venta Freshly hace que sea simple vender. Use su escáner de códigos de barras para agregar productos a los pedidos de los clientes.',
      PrintReceipts: 'Imprima Sus Recibos',
      PrintReceiptsSubtitle:
        'Siempre imprima recibos. Freshly facilita el cumplimiento de las regulaciones gubernamentales para entregar recibos impresos',
      ManageInventory: 'Gestione el Inventario',
      ManageInventorySubtitle:
        'Freshly facilita agregar y gestionar sus productos en el inventario. Use la aplicación gratuita de inventario de Freshly para gestionar rápidamente su stock',
      MultipleLocations: 'Varias Ubicaciones',
      MultipleLocationsSubtitle:
        'Gestione sus ubicaciones y ventas en tiempo real. Freshly le permite manejar todos los productos, inventario, clientes y ventas desde una sola ubicación',
      InventoryManagmentTitle: 'Gestión de inventario sencilla',
      InventoryManagment1:
        'Haz un seguimiento de los niveles de stock en tiempo real para evitar el exceso o la falta de existencias.',
      InventoryManagment2:
        'Configura alertas automáticas de bajo stock para adelantarte a la demanda.',
      InventoryManagment3:
        'Simplifica tu proceso de inventario con un sistema intuitivo y fácil de usar.',
      SalesAnalyticsTitle: 'Análisis de ventas en tiempo real',
      SalesAnalytics1:
        'Accede a datos de ventas en vivo para tomar decisiones informadas al instante.',
      SalesAnalytics2:
        'Monitorea ingresos y métricas de ventas desde cualquier dispositivo.',
      SalesAnalytics3:
        'Obtén información que te ayude a optimizar operaciones y aumentar ganancias.',
      LoyaltyProgramTitle: 'Programa de lealtad',
      LoyaltyProgram1:
        'Recompensa a los clientes con puntos, descuentos u ofertas exclusivas.',
      LoyaltyProgram2:
        'Sigue las compras de los clientes para crear recompensas personalizadas.',
      LoyaltyProgram3:
        'Fortalece las relaciones y mejora la retención de clientes.'
    },

    inventory: {
      InventorySetup: 'Configuración del Inventario',
      InventorySetupSubtitle:
        'Freshly facilita la configuración de su inventario. Nuestra aplicación de inventario para teléfonos móviles hace que agregar y gestionar productos desde una ubicación sea fácil y rápido.',
      UnlimitedStock: 'Stock Ilimitado',
      UnlimitedStockSubtitle:
        'Gestiona cada producto de tu inventario. Con stock ilimitado, puedes importar artículos de forma individual o en lote en cualquier momento.',
      StockAlert: 'Alerta de Stock',
      StockAlertSubtitle:
        'Monitoree fácilmente los niveles de stock de sus productos con Freshly. Reciba una notificación cuando cualquier producto alcance su nivel mínimo de cantidad. Nunca permita que los productos se agoten en stock.',
      PurchaseOrders: 'Órdenes de Compra',
      PurchaseOrdersSubtitle:
        'Reabastece en el momento adecuado con Freshly. Genera órdenes de compra para cualquier proveedor directamente desde el sistema.',
      InventoryCount: 'Conteo de Inventario',
      InventoryCountSubtitle:
        'Siempre sepa cómo está funcionando su negocio. Use la aplicación de inventario para contar cada producto en su inventario y sus niveles de stock. Nunca necesitará usar un bolígrafo y papel para contar su inventario.'
    },
    BusinessReports: {
      SalesReport: 'Informe de Ventas',
      SalesReportSubtitle:
        'Utilice sus datos de ventas, inventario y clientes en tiempo real para guiar su negocio hacia una mayor rentabilidad. Obtenga información sobre sus artículos más vendidos y sus niveles de stock',
      ExpenseReports: 'Informes de Gastos',
      ExpenseReportsSubtitle:
        'Tenemos la analítica que le ayudará a entender rápidamente de dónde provienen sus gastos. Aumente su ganancia reduciendo sus gastos tanto como sea posible.',
      CompleteReports: 'Informes Completos',
      CompleteReportsSubtitle:
        'Haga crecer su negocio con un potente motor de informes. Nunca tendrá que adivinar sus números. Acceda a métricas clave que le importan desde cualquier lugar.'
    },
    CustomerManagement: {
      ManageCustomers: 'Gestione Sus Clientes',
      ManageCustomersSubtitle:
        'Ahorre tiempo al construir su base de datos de clientes agregando sus clientes directamente en el punto de venta. Gestione los perfiles de sus clientes durante las transacciones.',
      HouseAccount: 'Cuenta de la Casa',
      HouseAccountSubtitle:
        'Nuestra función de cuenta de la casa le permite llevar un registro de las cuentas por cobrar de sus clientes. Con un proceso de pago simplificado. Nunca perderá un pago a partir de hoy.',
      LoyaltyProgram: 'Programa de Lealtad',
      LoyaltyProgramSubtitle:
        'Aumente sus ventas y retención de clientes recompensando a los clientes por negocios repetidos. Personalice sus propias reglas de lealtad para permitir a los clientes ganar puntos.'
    },

    EmployeeManagement: {
      EmployeeShift: 'Turno de Empleado',
      EmployeeShiftSubtitle:
        'Gestione todos los aspectos de sus empleados con Freshly. Los empleados pueden registrar su entrada con su código PIN al inicio del turno y el sistema llevará un registro de su tiempo.',
      EmployeeRights: 'Derechos de Acceso',
      EmployeeRightsSubtitle:
        'Proteja su negocio con Freshly. Todos sus empleados deberán usar su propia contraseña para acceder al sistema de punto de venta. Puede asignar permisos a cada uno de ellos.',
      EmployeePerformance: 'Rendimiento del Empleado',
      EmployeePerformanceSubtitle:
        'Obtenga informes detallados de cada uno de sus empleados. Conozca sus horas de trabajo, o su rendimiento en ventas. Mantenga un seguimiento del historial de pedidos de sus empleados.'
    },
    CustomerDisplay: {
      ConvenientForCustomers: 'Cómodo para los clientes',
      ConvenientForCustomersSubtitle:
        'Mejore la experiencia de venta para sus clientes y empleados a través de nuestro sistema de pantalla para clientes. Permita que sus clientes vean la información de su pedido antes de realizar su pago final.',
      WorksOffline: 'Funciona sin conexión',
      WorksOfflineSubtitle:
        'No necesita conectar el sistema de pantalla para clientes de Freshly a Internet. Funciona en una tableta separada de su sistema de punto de venta.'
    },
    pricing: {
      title:
        'Freshly funciona con tiendas de todo tipo y tamaño y entendemos que cada negocio tiene sus necesidades únicas'
    },

    description:
      'La aplicación de POS y cajero Freshly está diseñada para restaurantes y tiendas minoristas, con herramientas para inventario, ventas, clientes y más. Funciona en Android y iPad',
    tryItForFree: 'Prueba Freshly GRATIS sin contrato',
    tryItForFreeSubtitle:
      'Descarga Freshly ahora y comienza a vender en minutos',
    ProfessionalServices: 'Servicio Profesional',
    ProfessionalServicesSubtitle:
      'Un equipo de especialistas está listo y esperando para ayudarte a comenzar con Freshly. Nuestro equipo de soporte técnico está listo para asistir a cada uno de nuestros clientes. Estamos a solo una llamada de distancia',
    Pricing: 'Precios',
    ReportsSubHeader:
      'Puedes rastrear todo tipo de ventas desde cualquier lugar. Obtén informes detallados que te evitan visitar la tienda diariamente para seguimiento',
    StockSubtitle:
      'Freshly te ayuda a mantener un seguimiento del inventario disponible en tu tienda. Además, recibirás una alerta si el inventario está bajo',
    CustomerManagementSubtitle:
      'Tus clientes son tu éxito, con Freshly puedes guardar su información y comunicarte con ellos para aumentar tus ventas en tiempos de ofertas',
    'Spend less time on repetitive tasks so you can focus on doing what you love':
      'Pasa menos tiempo en tareas repetitivas para que puedas concentrarte en hacer lo que amas.',
    title: 'Freshly POS | Sistema de POS para iPad y Android',
    TitlePart: ' | Freshly POS',
    InventoryTitle: 'Gestión de Inventario | Freshly POS',
    ReportsTitle: 'Informes Empresariales | Freshly POS',
    CustomerManagementTitle: 'Gestión de Clientes | Freshly POS',
    EmployeeManagementTitle: 'Gestión de Empleados | Freshly POS',
    CustomerDisplayTitle: 'Pantalla para Clientes | Freshly POS',
    HardwareTitle: 'Hardware | Freshly POS',
    PricingTitle: 'Precios | Freshly POS',
    RetailTitle: 'Minorista | Freshly POS',
    GroceryStoreTitle: 'Tienda de Abarrotes | Freshly POS',
    RestaurantsTitle: 'Restaurantes | Freshly POS',
    SupportTitle: 'Soporte | Freshly POS',
    'Learn More': 'Aprende Más',
    'Get Free Demo': 'Obtén Demo Gratis',
    'Get Your Free Demo': 'Obtén Tu Demo Gratis',
    requestDemo: 'Solicitar Demo',
    YourDemoContains:
      'Esta demo contiene un curso de entrenamiento para las aplicaciones de Freshly',

    CompleteThisForm:
      'Complete este formulario para recibir una demostración gratuita',
    'Everything For Your Business': 'Sistema de Punto de Venta Flexible',
    'Simplifies running your business, by allowing you to streamline your orders':
      'Diseñado para hacer sus operaciones más rápidas. Gestione su restaurante o tienda desde cualquier lugar en Android o iPad',
    'We will be in contact soon': 'Estaremos en contacto pronto',
    'Discover how to improve your business with us':
      'Descubra cómo mejorar su negocio con Freshly',
    'Your full name': 'Su nombre completo',
    'Your Phone number': 'Su número de teléfono',
    'Your email': 'Su correo electrónico',
    'Select Country': 'Seleccione País',
    SelectPhoneCode: 'Código de área',
    'You must write a correct e-mail':
      'Debe escribir un correo electrónico correcto',
    Submit: 'Enviar',
    'Grocery Store': 'Tienda de Abarrotes',
    Retail: 'Minorista',
    'Food Truck': 'Camión de Comida',
    'Coffee Shop': 'Cafetería',
    Bakary: 'Panadería',
    '+More': '+Más',
    Arabic: 'Árabe',
    English: 'Inglés',
    Spanish: 'Español',
    Language: 'Idioma',
    'Your Store Deserves Better System': 'Su Tienda Merece un Mejor Sistema',
    'No problem is too small':
      'No hay problema demasiado pequeño para nuestros expertos en soporte. Disponibles todo el año para responder su llamada, correo electrónico o chat web',
    'Remote Support 24/7': 'Soporte Remoto 24/7',
    'Employee Training': 'Capacitación de Empleados',
    'Learn the system':
      'Aprenda el sistema y capacite rápidamente a su personal. Un miembro dedicado del equipo trabajará con usted para personalizar sus configuraciones',
    'Multi Language Support': 'Soporte Multilingüe',
    'Your system supports a variety of languages':
      'Su sistema admite una variedad de idiomas, comenzando con árabe e inglés. Cada empleado debe tener su elección de idioma individual al usar el sistema',
    'Powerful Features That Go the Distance':
      'Características Potentes Que Van Más Allá',
    'Delivery Management': 'Gestión de Entregas',
    'Manage your orders':
      'Gestione sus pedidos y cronogramas de entrega con un sistema de entregas mejor y más rápido. Asigne pedidos a conductores y genere registros de prueba de entrega',
    'Offline Mode Capability': 'Capacidad de Modo sin Conexión',
    'Internet connectivity issues, keep using your system and all your data and work is automatically synced to the cloud once you are back online':
      'Problemas de conectividad a Internet, siga usando su sistema y todos sus datos y trabajos se sincronizarán automáticamente con la nube una vez que esté en línea',
    'Employee Management': 'Gestión de Empleados',
    'Use our multi-level':
      'Utilice nuestro control de acceso multinivel para gestionar el acceso de sus empleados. Administre la hoja de tiempo, tareas y rendimiento de los empleados',
    'Customer Display Screen': 'Pantalla para Clientes',

    'Improve your customer experience':
      'Mejore la experiencia de sus clientes con una pantalla de visualización para clientes',
    LowInventoryNotification: 'Notificación de Bajo Inventario',
    LowInventoryMsg:
      'Recibe alertas automáticas cuando los niveles de tu inventario estén por debajo de los umbrales que establezcas.',
    'Regular System Updates': 'Actualizaciones Regulares del Sistema',
    'Updates allows your store to take advantage of newer features that have been recently introduced to the platform, with no cost to you':
      'Las actualizaciones permiten que su tienda aproveche las características más recientes que se han introducido en la plataforma, sin ningún costo para usted',
    'Automate Your Business': 'Automatice Su Negocio',
    'Control your business':
      'Controle su negocio dentro de una plataforma. Administrar sus empleados y realizar un seguimiento de su inventario se vuelve simple con FreshSUQ. Utilice su panel de control o consola de administración para agilizar todos sus procesos de negocio',
    'Transform your tablet into an intelligent point of sale machine':
      'Transforme su tableta en una máquina inteligente de punto de venta',
    'Inventory App': 'Aplicación de Inventario',
    'Manage your inventory':
      'Gestione su inventario con nuestra aplicación de inventario. Utilice la aplicación para realizar su conteo físico de inventario, agregar o eliminar artículos de su inventario',
    'Data Security': 'Seguridad de Datos',
    'We protect your sensitive business data and your customers’ information from potential threats with our reliable and secure platform':
      'Protegemos sus datos comerciales sensibles y la información de sus clientes de posibles amenazas con nuestra plataforma confiable y segura',
    'Third Party Integration': 'Integración de Terceros',
    'Integrate third party apps directly':
      'Integre aplicaciones de terceros directamente en su sistema. ¡Obtenga más capacidades sin incurrir en costos operativos adicionales!',
    'Real-time Analytics': 'Análisis en Tiempo Real',
    'Utilize your real-time sales':
      'Utilice sus datos de ventas, inventario o clientes en tiempo real para guiar su negocio hacia una mayor rentabilidad. Obtenga información sobre sus artículos más vendidos y sus niveles de stock. Genere informes para ayudarle a gestionar sus empleados y su productividad',
    Home: 'Inicio',
    Features: 'Características',
    Hardware: 'Hardware',
    Price: 'Precio',
    'Manage Your Employees Easily with FreshSUQ':
      'Gestione Fácilmente a Sus Empleados con Freshly',
    'Build a better relationship with your customers':
      'Construya una mejor relación con sus clientes',
    'Reports to help you take better control of your business':
      'Informes para ayudarle a tomar un mejor control de su negocio',
    'Best Inventory Management to Increase Your Profit':
      'La Mejor Gestión de Inventario para Aumentar Su Beneficio',
    'Inventory Management': 'Gestión de Inventario',
    'Basic Inventory Management': 'Gestión de Inventario Básica',
    'Basic Features': 'Básico',
    Advanced: 'Avanzado',
    'Advaned Inventory Management': 'Gestión de Inventario Avanzada',
    Annually: 'Anualmente',
    Annual: 'Anual',
    Monthly: 'Mensual',

    'Annual payment': 'El total se paga anualmente',
    'Monthly payment': 'La cantidad se paga mensualmente',
    'Multiple locations': 'Ubicaciones',
    'one license': 'Registros',
    'Manage your inventory in real-time':
      'Gestione su inventario en tiempo real, Rastree la cantidad de sus artículos en stock. Reciba alertas cuando los artículos estén bajos. Organice su inventario por categoría',
    'Reporting and Analytics': 'Informes y Análisis',
    'Organizes all your data into a clear, friendly, real-time reports, accessible from your desktop or smartphone  Make informed decisions with customized reports':
      'Organiza todos sus datos en informes claros y amigables en tiempo real, accesibles desde su escritorio o smartphone. Tome decisiones informadas con informes personalizados',
    'Customer management': 'Gestión de Clientes',
    'Build your customer database, Create customer profiles, and search your customers order history and personal contact information':
      'Construya su base de datos de clientes, Cree perfiles de clientes y busque el historial de pedidos de sus clientes e información de contacto personal',
    Company: 'Empresa',
    'About Us': 'Acerca de Nosotros',
    Support: 'Soporte',
    Terms: 'Términos',
    Privacy: 'Privacidad',
    'Contact Info': 'Información de Contacto',
    'Copyright 2020 FreshSUQ systems': 'Copyright 2020 Sistemas Freshly',
    'We work hard to help you get the most out of your business':
      'Trabajamos arduamente para ayudarlo a obtener el máximo provecho de su negocio',
    'At FreshSUQ, we’re passionate about providing a hassle-free platform for business owners':
      'En Freshly, nos apasiona proporcionar una plataforma sin complicaciones para los propietarios de negocios',
    'We focus on creating solutions for those who need help improving their business workflow and reducing their costs':
      'Nos enfocamos en crear soluciones para aquellos que necesitan ayuda para mejorar el flujo de trabajo de su negocio y reducir sus costos',
    'We are truly excited to belong to a community of business owners who are eager to optimize their business':
      'Estamos verdaderamente emocionados de pertenecer a una comunidad de propietarios de negocios que están ansiosos por optimizar su negocio',
    'success is defined by our customers':
      'El éxito de nuestra compañía se define por la satisfacción de nuestros clientes, por eso trabajamos arduamente en la calidad de Freshly. Es nuestra misión ayudarlo a simplificar sus necesidades comerciales y guiarlo hacia una mayor rentabilidad',
    'Fair & Simple Pricing': 'Precios Justos y Simples. Mejor Valor',
    '24/7 Support': 'Soporte 24/7',
    Products: 'Productos',
    Employees: 'Empleados',
    Unlimited: 'Ilimitados',
    Customers: 'Clientes',
    'Secure cloud backup': 'Respaldo en la nube seguro',
    'Purchase orders': 'Órdenes de Compra',
    'Customer display': 'Pantalla para Clientes',
    'Employee timesheet': 'Hoja de Tiempo de Empleados',
    'Reports & analytics': 'Informes y Análisis',
    'Delivery log & history': 'Registro e Historial de Entregas',
    'QuickBooks / xero Integration': 'Integración con QuickBooks / xero',
    'Dedicated account manager': 'Gerente de cuenta dedicado',
    'Third party integration': 'Integración de terceros',
    'Customized onboarding': 'Integración personalizada',
    'Extra Location': 'Ubicación adicional',
    'Extra Register': 'Caja adicional',
    'Extra Employee': 'Empleado adicional',
    'Employee Management': 'Gestión de empleados',
    'Add-ons': 'Adiciones',
    month: 'mes',
    Free: 'Gratis',
    Baisc: 'Básico',
    Plus: 'Plus',
    Pro: 'Pro',
    Select: 'Seleccionar',

    Recommended: 'Recomendado',
    'We will reach out to you very soon':
      'Nos pondremos en contacto con usted muy pronto',
    'Saudi Arabia': 'Arabia Saudita',
    'United Arab Emirates': 'Emiratos Árabes Unidos',
    Qatar: 'Qatar',
    Bahrain: 'Baréin',
    Egypt: 'Egipto',
    Oman: 'Omán',
    Jordan: 'Jordania',
    Lebanon: 'Líbano',
    'United States': 'Estados Unidos',
    Support_msg1:
      'Estamos trabajando en construir un portal completo de soporte técnico',
    Support_msg2:
      'si tiene alguna pregunta de soporte por favor envíenos un correo electrónico a',
    'Free Trial': 'Comience Ahora',
    'Try Trial': 'Comience Ahora',
    pricing_header: '¡45 días de prueba GRATIS con todos los planes!',
    pricing_subheader:
      'Nuestros precios son simples y puede cancelar o cambiar su plan en cualquier momento.',
    hardware: 'Hardware',
    hardware_list: 'Lista de Hardware',
    tablet_stand: 'Soporte para Tablet',
    tablet_stand_msg:
      'Soporte para tablet con cerradura de seguridad. Apto para todas las tabletas de 7.9 a 12.9 pulgadas',
    barcode_scanner: 'Escáner de Códigos de Barras',
    barcode_scanner_body:
      'Escáner de códigos de barras Bluetooth, compatible con IOS y Android',
    SAR: 'SAR',
    HardwareFor: 'Hardware para Freshly POS',
    ForSupportedList:
      'Para una lista del hardware compatible con Freshly, por favor envíenos un correo electrónico a ',
    allRights: 'Freshly 2025 Todos los Derechos Reservados',
    pricesSubjectToChange:
      'Los precios están sujetos a cambios sin previo aviso',
    LimitedTime: 'Oferta por Tiempo Limitado',
    Menu: 'Menú',
    Faq: 'Preguntas Frecuentes',
    HowToUseFreshSUQ: '¿Cómo uso la aplicación Freshly?',
    HowToUseFreshSUQMsg:
      'Comience descargando la aplicación en Apple Store o Google Play Store. Cree su cuenta en la aplicación o en la consola de administración. Luego agregue sus artículos usando la aplicación de inventario o en la consola de administración. Puede editar la información de su cuenta, agregar impuestos y descuentos en la consola de administración.',
    DoesFreshsuqOffline: '¿Funciona la aplicación Freshly sin conexión?',
    DoesFreshsuqOfflineMsg:
      'FreshSUQ funciona sin conexión. Puede realizar ventas, agregar nuevos artículos y descuentos sin conexión a internet. Sin embargo, para subir sus ventas y los nuevos artículos o descuentos a la consola de administración tendrá que conectarse a internet para sincronizar sus datos con la consola de administración',
    WhatDevicesWorkWithFreshsuq:
      '¿Qué tipo de dispositivos funcionan con la aplicación Freshly?',
    WhatDevicesWorkWithFreshsuqMsg:
      'La aplicación FreshSUQ funciona en IPad y cualquier tableta Android. Puede conectar cualquier escáner de códigos de barras Bluetooth, impresora de recibos sugerimos impresoras de recibos de red Epson y cualquier caja registradora. Si su hardware no es compatible por favor envíenos un correo electrónico y lo agregaremos a los dispositivos compatibles con FreshSUQ.',
    HowToSignup: '¿Cómo puedo registrarme en Freshly?',
    HowToSignupMsg:
      'Puede registrarse haciendo clic en Comenzar con Freshly y completando el formulario o puede descargar la aplicación FreshSUQ y completar las preguntas de registro en la aplicación.',
    SignUpForFree: 'Regístrese Ahora Gratis',
    POSSectionTitle:
      'Una Aplicación para Gestionar Todas sus Necesidades de Tienda',
    POSSectionSubTitle:
      'Sabemos que las regulaciones están cambiando constantemente. Pero tenemos todas las opciones para administrar su tienda completa',
    HowItWorks: 'Cómo Funciona',
    InventorySectionTitle: 'Aplicación de Inventario',
    InventorySectionSubTitle:
      'Use su teléfono como un escáner de códigos de barras portátil para agregar o actualizar productos',
    AddNewProducts: 'Agregar Nuevos Productos',
    AddNewProductsMsg:
      'Agrega y gestiona tu inventario fácilmente con Freshly. Agrega productos directamente desde la consola de administración o la aplicación de inventario.',
    SalesReports: 'Informes de Ventas',
    SalesReportsMsg:
      'Obtenga una mejor visión de las transacciones diarias de su tienda. Una mirada detallada a las compras y devoluciones listadas por día. Le ayuda a optimizar sus elecciones de personal y marketing',
    TaxReports: 'Informes de Impuestos',
    TaxReportsMsg:
      'Con Freshly no necesita otro software para calcular sus obligaciones fiscales. Freshly cumple completamente con los impuestos y genera informes que puede usar para presentar sus impuestos',
    PaymentsReports: 'Informes de Pagos',
    PaymentsReportsMsg:
      'El informe de pagos le ofrece una mirada más cercana a los métodos de pago preferidos de sus clientes. Obtenga una lista detallada de los totales de pagos en efectivo y con tarjeta y reembolsos',
    Reports: 'Informes',
    ReportsSubsection:
      'Informes que le muestran todos los detalles de su tienda - inventario, ventas, clientes y empleados',
    BuseinssTypes: 'Tipos de Negocio',
    groceryStores: 'Tiendas de Abarrotes',

    Barbershop: 'Barbería',
    restaurants: 'Restaurantes',
    coffeeShop: 'Cafetería',
    quickService: 'Servicio Rápido',
    foodTrucks: 'Camión de Comida',
    forRetails: 'Para Minoristas',
    stores: 'Tienda Minorista',
    forRestaurants: 'Para Restaurantes',
    groceryStoresSubtitle:
      'Diseñado completamente para la gestión de tiendas de abarrotes y supermercados. Contiene más de 100K artículos listos para ser agregados a su inventario',
    retailStoresSubtitle:
      'Sistema flexible diseñado para minoristas. Contiene todas las herramientas que necesita para administrar y gestionar su tienda e inventario desde cualquier lugar',
    restaurantSSubtitle:
      'Sistema completo diseñado para la gestión de restaurantes. Utilice las herramientas adecuadas para administrar su restaurante de manera más eficiente y servir mejor a su cliente',
    coffeeShopSubtitle:
      'Sistema flexible diseñado para la gestión de cafeterías. Contiene todas las herramientas para servir mejor a sus clientes',
    quickServiceSubtitle:
      'Sistema flexible diseñado para servicios rápidos. Le permite agregar pedidos más rápido y mantener a su personal eficiente',
    foodTrucksSubtitle:
      'Sistema flexible diseñado para camiones de comida. Le permite agregar pedidos más rápido y mantener a su personal organizado',
    kds: 'Pantalla de Visualización de Cocina',
    kdsSubtitle:
      'Mejore la precisión de los pedidos y aumente la eficiencia. El sistema de visualización de cocina le permite acelerar el servicio con tickets en pantalla para reducir los tiempos de espera largos y pedidos perdidos',
    tablesManagement: 'Gestión de Mesas',
    tablesManagementSub:
      'El sistema de gestión de mesas facilita diseñar su plano de piso y reorganizarlo fácilmente. Tome pedidos por mesa y gestione la capacidad de su restaurante',
    inventorySubheader:
      'El inventario es el aspecto más importante de su negocio que tiene la capacidad de aumentar su beneficio. Construimos una aplicación dedicada a gestionar todo su inventario y hacer el proceso de inventario mucho más fácil',
    groceryStoreText:
      'Sabemos que su sistema de punto de venta es el software más importante en su tienda. Es por eso que diseñamos nuestro sistema para funcionar incluso si se desconecta el internet.',
    restaurantsText:
      'Gestione pedidos, ventas y pagos en un solo lugar con un sistema seguro basado en la nube. Con toda la tecnología que necesita para administrar su restaurante en una plataforma fácil.',
    coffeeShopText:
      'Gestione pedidos, ventas y pagos en un solo lugar con un sistema seguro basado en la nube. Con toda la tecnología que necesita para administrar su cafetería en una plataforma fácil.',
    howToDoIt: 'Cómo Hacerlo',
    changeHere: 'Editar Aquí',
    gettingStartedTitle: 'Comenzando',
    gettingStartedTitlePage: 'FreshSUQ POS | Comenzando',
    gettingStartedTitleBody:
      'Esta lista le ayudará a configurar FreshSUQ POS para su tienda. Siga los pasos a continuación para comenzar',
    generalSettings: 'Configuración General',
    generalSettingsSteps: {
      vat_id:
        'Configure la información general de la tienda incluyendo el ID de IVA si es necesario',
      store: 'Configure la información de ubicación de la tienda',
      taxes: 'Configure los impuestos de su tienda si es necesario',
      employees: 'Agregue a sus empleados al sistema'
    },
    InventorySetup: 'Configuración de Inventario',
    InventorySetupSteps: {
      add_categories: 'Agregue categorías de productos según lo necesite',
      add_products: 'Agregue productos individualmente o en masa'
    },

    HardwareSetup: 'Configuración de Hardware',
    tryNow: 'Pruebe Ahora',
    numberOfLocations: 'Número de Ubicaciones',
    numberOfRegisters: 'Número de Cajas',
    extraLocations: 'Ubicaciones Extras',
    extraRegisters: 'Cajas Extras',
    basePlanCost: 'Costo del Plan Base',
    discount: 'Descuento',
    firstMonthFree: 'Comience ahora con una prueba gratuita de 30 días',
    creditCardNotReqired: 'Tarjeta de crédito no requerida',
    seePricing: 'Mostrar Precios',
    subscribeAndUseNow: 'Únase ahora y comience con Freshly en minutos',
    HardwareSetupSteps: {
      list_of_hardware:
        'Lista de hardware que necesita para trabajar con Freshly POS',
      tablet: 'Tableta, iPad o Android',
      wifi_router:
        "Router wifi de Internet 'para conectar a la impresora de ethernet'",
      receipt_printer: 'Impresora de recibos con puerto ethernet',
      cash_drawer: "Caja de dinero 'opcional'",
      barcode_scanner: "Escáner de códigos de barras Bluetooth 'opcional'",
      download_app:
        'Descargue Freshly pos en su tableta desde App Store o Play Store',
      register:
        'Regístrese o inicie sesión en la aplicación POS usando su correo electrónico y contraseña',
      connect: 'Conecte su tableta e impresora al mismo router',
      connect_tablet: 'Conecte la tableta a la red wifi',
      connect_printer:
        'Conecte su impresora al router wifi usando el cable ethernet',
      set_printer: 'Configure su impresora',
      set_barcode: 'Configure su escáner de códigos de barras'
    }
  }
}
